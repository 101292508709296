/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoggedInInfoResponseDTO } from '../models/LoggedInInfoResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MyBusinessLoggedInDataControllerService {

    /**
     * Returns info of the logged in user and context
     * #token.isAuthenticated()
     * @returns LoggedInInfoResponseDTO OK
     * @throws ApiError
     */
    public static getLoggedInDataUsingGet(): CancelablePromise<LoggedInInfoResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/loggedindata',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductAvailability } from '../models/ProductAvailability';
import type { RequestProduct } from '../models/RequestProduct';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StockStatusResourceService {

    /**
     * Gets stock status for specific product ids
     * @param requestBody
     * @param useColt
     * @returns ProductAvailability OK
     * @throws ApiError
     */
    public static getMulti(
        requestBody: Array<RequestProduct>,
        useColt?: boolean,
    ): CancelablePromise<Record<string, ProductAvailability>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sales-stock-status/v1',
            query: {
                'useColt': useColt,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Gets stock status for the requested productId
     * @param productId
     * @param useColt
     * @returns ProductAvailability OK
     * @throws ApiError
     */
    public static get(
        productId: number,
        useColt?: boolean,
    ): CancelablePromise<ProductAvailability> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sales-stock-status/v1/{productId}',
            path: {
                'productId': productId,
            },
            query: {
                'useColt': useColt,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

}
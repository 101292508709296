/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NetworkInfoRequest } from '../models/NetworkInfoRequest';
import type { NetworkInfoSuccessResponse } from '../models/NetworkInfoSuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Fetch detailed Precheck data for a given networkId
     * @param body
     * @returns NetworkInfoSuccessResponse Operation went as expected.
     * @throws ApiError
     */
    public static networkInfoV1(
        body: NetworkInfoRequest,
    ): CancelablePromise<NetworkInfoSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1',
            body: body,
            errors: {
                400: `Request is missing a required field, or has faulty formatting.`,
                500: `Unexpected error.`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganizationsWithServiceWebsDTO } from '../models/OrganizationsWithServiceWebsDTO';
import type { ResponseEntity } from '../models/ResponseEntity';
import type { ServiceWebListDTO } from '../models/ServiceWebListDTO';
import type { UpdateServiceWebsRequestDTO } from '../models/UpdateServiceWebsRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MyBusinessServiceWebControllerService {

    /**
     * getServiceWebsForAccessibleOrganizationsInGroup
     * #token.hasAccess('MANAGE_USER')
     * @param groupId groupId
     * @returns OrganizationsWithServiceWebsDTO OK
     * @throws ApiError
     */
    public static getServiceWebsForAccessibleOrganizationsInGroupUsingGet(
        groupId: number,
    ): CancelablePromise<OrganizationsWithServiceWebsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/servicewebs',
            path: {
                'groupId': groupId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * setServiceWebsForUserProfile
     * #token.hasAccess('MANAGE_USER')
     * @param groupId groupId
     * @param updateServiceWebsRequestDto updateServiceWebsRequestDTO
     * @param userId userId
     * @returns ResponseEntity OK
     * @returns any Created
     * @throws ApiError
     */
    public static setServiceWebsForUserProfileUsingPut(
        groupId: number,
        updateServiceWebsRequestDto: UpdateServiceWebsRequestDTO,
        userId: string,
    ): CancelablePromise<ResponseEntity | any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/users/tcwss/{userId}/servicewebs',
            path: {
                'groupId': groupId,
                'userId': userId,
            },
            body: updateServiceWebsRequestDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get all available Service Webs for a given user profile
     * #token.requestOnSelf() || #token.hasAccess('MANAGE_USER')
     * @param groupId groupId
     * @param userId userId
     * @returns ServiceWebListDTO List of available service webs
     * @throws ApiError
     */
    public static getServiceWebsUsingGet(
        groupId: number,
        userId: string,
    ): CancelablePromise<ServiceWebListDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/users/{userId}/servicewebs',
            path: {
                'groupId': groupId,
                'userId': userId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
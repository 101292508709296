/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResponseWrapper } from '../models/ResponseWrapper';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SimCardControllerService {

    /**
     * Validate ICC number
     * @param icc icc
     * @returns ResponseWrapper Successful request
     * @throws ApiError
     */
    public static validateIccUsingGet(
        icc: string,
    ): CancelablePromise<ResponseWrapper> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/validate/{icc}',
            path: {
                'icc': icc,
            },
            errors: {
                400: `Bad ICC format. Response will contain an errorCode and errorMessage.`,
                500: `Internal server error`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailableChangesRequest } from '../models/AvailableChangesRequest';
import type { ResponseWrapperCollectionEngagement } from '../models/ResponseWrapperCollectionEngagement';
import type { ResponseWrapperCollectionUserEngagement } from '../models/ResponseWrapperCollectionUserEngagement';
import type { ResponseWrapperEngagementAndAvailableChanges } from '../models/ResponseWrapperEngagementAndAvailableChanges';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class V2CiamAuthService {

    /**
     * @param requestBody
     * @returns ResponseWrapperEngagementAndAvailableChanges Operation went as expected.
     * @throws ApiError
     */
    public static getEngagementAndAvailableChanges(
        requestBody: AvailableChangesRequest,
    ): CancelablePromise<ResponseWrapperEngagementAndAvailableChanges> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/available-changes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Request is missing a required field, or has faulty formatting.`,
                500: `Unexpected error.`,
            },
        });
    }

    /**
     * @param category
     * @returns ResponseWrapperCollectionEngagement Operation went as expected.
     * @throws ApiError
     */
    public static getEngagements(
        category: string,
    ): CancelablePromise<ResponseWrapperCollectionEngagement> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/engagements/{category}',
            path: {
                'category': category,
            },
            errors: {
                400: `Request is missing a required field, or has faulty formatting.`,
                500: `Unexpected error.`,
            },
        });
    }

    /**
     * @param category
     * @param productId
     * @param commitmentPeriod
     * @param promotionId
     * @returns ResponseWrapperCollectionUserEngagement Operation went as expected.
     * @throws ApiError
     */
    public static getExtendable(
        category: string,
        productId: number,
        commitmentPeriod: string,
        promotionId: string,
    ): CancelablePromise<ResponseWrapperCollectionUserEngagement> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/engagements/{category}/extendable',
            path: {
                'category': category,
            },
            query: {
                'productId': productId,
                'commitmentPeriod': commitmentPeriod,
                'promotionId': promotionId,
            },
            errors: {
                400: `Request is missing a required field, or has faulty formatting.`,
                500: `Unexpected error.`,
            },
        });
    }

}
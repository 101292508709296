/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SelectionRequest } from '../models/SelectionRequest';
import type { SelectionResponseWrapper } from '../models/SelectionResponseWrapper';
import type { SubscriptionsSelectionResponseWrapper } from '../models/SubscriptionsSelectionResponseWrapper';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class V2Service {

    /**
     * Create a selection with price calculations based on request data. The response does not include products that are marked as hidden or internal in the offering, with a few exceptions.
     * @param requestBody
     * @returns SelectionResponseWrapper Operation went as expected.
     * @throws ApiError
     */
    public static select(
        requestBody: SelectionRequest,
    ): CancelablePromise<SelectionResponseWrapper> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/selection',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Request is missing a required field, or has faulty formatting.`,
                500: `Unexpected error.`,
            },
        });
    }

    /**
     * Return price data about subscriptions from a single offering
     * @param offeringId Offering ID (aka ALID) from the Product Editor. Minimum value is 0 and maximum length is 6 digits.
     * @returns SubscriptionsSelectionResponseWrapper Operation went as expected
     * @throws ApiError
     */
    public static selectSubscriptions(
        offeringId: number,
    ): CancelablePromise<SubscriptionsSelectionResponseWrapper> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/selection/subscriptions/{offeringId}',
            path: {
                'offeringId': offeringId,
            },
            errors: {
                400: `Request is missing a required field, or has faulty formatting.`,
                500: `Unexpected error.`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailableCampaignsRequest } from '../models/AvailableCampaignsRequest';
import type { AvailableCampaignsSuccessResponse } from '../models/AvailableCampaignsSuccessResponse';
import type { ReadOfferingRequest } from '../models/ReadOfferingRequest';
import type { ReadOfferingSuccessResponse } from '../models/ReadOfferingSuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Fetch groups, products and metadata for an offering.
     * @param body
     * @returns AvailableCampaignsSuccessResponse Operation went as expected.
     * @throws ApiError
     */
    public static readOfferingAvailableCampaignsV1(
        body: AvailableCampaignsRequest,
    ): CancelablePromise<AvailableCampaignsSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/availablecampaigns/v1',
            body: body,
            errors: {
                400: `Request is missing a required field, or has faulty formatting.`,
                500: `Unexpected error.`,
            },
        });
    }

    /**
     * This endpoint is for internal use, only accessible from within the cluster/Telia network.
     * Fetch groups, products and metadata for an offering.
     * The difference between this and the public endpoint is that this will return products that are configured as internal and hidden in the Product Editor.
     * @param body
     * @returns ReadOfferingSuccessResponse Operation went as expected.
     * @throws ApiError
     */
    public static readOfferingInternalV1(
        body: ReadOfferingRequest,
    ): CancelablePromise<ReadOfferingSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal/v1',
            body: body,
            errors: {
                400: `Request is missing a required field, or has faulty formatting.`,
                500: `Unexpected error.`,
            },
        });
    }

    /**
     * Fetch groups, products and metadata for an offering.
     * @param body
     * @returns ReadOfferingSuccessResponse Operation went as expected.
     * @throws ApiError
     */
    public static readOfferingV1(
        body: ReadOfferingRequest,
    ): CancelablePromise<ReadOfferingSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1',
            body: body,
            errors: {
                400: `Request is missing a required field, or has faulty formatting.`,
                500: `Unexpected error.`,
            },
        });
    }

}
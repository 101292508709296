/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetCustomerPermissionsSuccessResponse } from '../models/GetCustomerPermissionsSuccessResponse';
import type { SetCustomerPermissionsRequest } from '../models/SetCustomerPermissionsRequest';
import type { SetCustomerPermissionsSuccessResponse } from '../models/SetCustomerPermissionsSuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class V1Service {

    /**
     * Fetches customer permissions (consent)
     * @returns GetCustomerPermissionsSuccessResponse OK
     * @throws ApiError
     */
    public static getCustomerPermissions1(): CancelablePromise<GetCustomerPermissionsSuccessResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/customer-permissions',
        });
    }

    /**
     * Sets customer permissions (consent)
     * @param requestBody
     * @returns SetCustomerPermissionsSuccessResponse OK
     * @throws ApiError
     */
    public static setCustomerPermissions1(
        requestBody: SetCustomerPermissionsRequest,
    ): CancelablePromise<SetCustomerPermissionsSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/customer-permissions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
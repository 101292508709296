/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RandomIdentifierRequest } from '../models/RandomIdentifierRequest';
import type { RandomIdentifierResponseListResult } from '../models/RandomIdentifierResponseListResult';
import type { RandomIdentifierResponseResult } from '../models/RandomIdentifierResponseResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * @param body
     * @returns RandomIdentifierResponseResult successful operation
     * @throws ApiError
     */
    public static persist(
        body?: RandomIdentifierRequest,
    ): CancelablePromise<RandomIdentifierResponseResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/random-identifier',
            body: body,
        });
    }

    /**
     * @param body
     * @returns RandomIdentifierResponseListResult successful operation
     * @throws ApiError
     */
    public static getMulti(
        body?: Array<string>,
    ): CancelablePromise<RandomIdentifierResponseListResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/random-identifier/multi',
            body: body,
        });
    }

    /**
     * @param token
     * @returns RandomIdentifierResponseResult successful operation
     * @throws ApiError
     */
    public static get(
        token: string,
    ): CancelablePromise<RandomIdentifierResponseResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/random-identifier/{token}',
            path: {
                'token': token,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CorpCaseExportService {

    /**
     * Export cases
     * @returns string OK
     * @throws ApiError
     */
    public static exportCases1({
        xAuthScopeId,
        acceptLanguage,
        accept,
        sortBy,
        relatedCaseIds,
        sortDirection,
        size,
        start,
        organizationTscIds,
        statuses,
        categories,
        productCodes,
        excludeCloseCodes,
        createdFrom,
        createdTo,
        textSearch,
        c2BUid,
    }: {
        xAuthScopeId: any,
        acceptLanguage?: any,
        accept?: 'text/csv' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        /** Attribute to sort cases by. **/
        sortBy?: 'CASE_ID' | 'SHORT_DESCRIPTION' | 'STATUS' | 'CATEGORY' | 'UPDATED_ON' | 'SUBSCRIPTION_ALIAS' | 'CREATED_ON' | 'SUBSCRIPTION_ID' | 'REPORTING_PERSON' | 'CUSTOMER_REFERENCE' | 'COUNTRY' | 'CITY' | 'STREET' | 'ORGANIZATION_NUMBER' | 'ORGANIZATION_NAME' | 'SUBSCRIPTION_PRODUCT_NAME' | 'SERVICE_OBJECT_NAME' | 'DESCRIPTION' | 'CLOSE_NOTES' | 'CLOSE_CODE' | 'RESOLVED_ON' | 'DURATION' | 'BUSINESS_ELAPSED_TIME' | 'CLOSED_ON' | 'IMPACT' | 'URGENCY' | 'PRIORITY' | 'GUARANTEED_TIME' | 'CALCULATE_SLA' | 'HAS_BREACHED_SLA',
        relatedCaseIds?: Array<string>,
        sortDirection?: 'ASC' | 'DESC',
        size?: number,
        start?: number,
        organizationTscIds?: Array<string>,
        statuses?: Array<'NEW' | 'OPEN' | 'AWAITING_INFO' | 'RESOLVED' | 'CLOSED' | 'CANCELLED' | 'UNRECOGNIZED'>,
        categories?: Array<'GDPR' | 'INCIDENT' | 'SERVICE_REQUEST' | 'INVOICE' | 'PERSONAL_TECHNICIAN'>,
        productCodes?: Array<string>,
        excludeCloseCodes?: Array<string>,
        /** Date formats: YYYY-MM-DD **/
        createdFrom?: string,
        /** Date formats: YYYY-MM-DD **/
        createdTo?: string,
        /** String for searching cases by keyword or part of a keyword. The search is performed on the following attributes: caseId, shortDescription and subscriptionId. **/
        textSearch?: string,
        c2BUid?: string,
    }): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/cases/export',
            headers: {
                'Accept-Language': acceptLanguage,
                'Accept': accept,
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'sortBy': sortBy,
                'relatedCaseIds': relatedCaseIds,
                'sortDirection': sortDirection,
                'size': size,
                'start': start,
                'organizationTscIds': organizationTscIds,
                'statuses': statuses,
                'categories': categories,
                'productCodes': productCodes,
                'excludeCloseCodes': excludeCloseCodes,
                'createdFrom': createdFrom,
                'createdTo': createdTo,
                'textSearch': textSearch,
                'c2bUid': c2BUid,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                405: `Method Not Allowed`,
                415: `Unsupported Media Type`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CacheSuccessResponse } from '../models/CacheSuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalServiceClearingCacheForTheDifferentEndpointsInOfferingCatalogService {

    /**
     * Clears offerings cache by ids.
     * @param requestBody
     * @returns CacheSuccessResponse Successful operation
     * @throws ApiError
     */
    public static clear(
        requestBody: Array<number>,
    ): CancelablePromise<CacheSuccessResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/dcse-pe-offering-catalog/cache/clear/offerings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Clears cache for a specific offering catalog endpoint by cacheId.
     * OFFERING_LIST Takes Sales Channel as cacheId.<br/>OFFERING Takes a single offering ID as cacheId.<br/>CAMPAIGN_LIST Cannot be individually cleared.<br/>CAMPAIGN Takes a single campaign ID as cacheId.<br/>INSURANCE Cannot be individually cleared.<br/>PRODUCT_LIST Takes a single product ID as cacheId.<br/><br/>Replace {cacheId} with all to clear all cache for specific endpoint<br/>Replace {cacheMap}/{cacheId} with all to clear all cache<br/><br/>
     * @param cacheMap
     * @param cacheId
     * @returns CacheSuccessResponse Successful operation
     * @throws ApiError
     */
    public static clear1(
        cacheMap: 'OFFERING_LIST' | 'PERSONALIZED_OFFERING_LIST' | 'OFFERING_LIST_FOR_MARTECH' | 'OFFERING' | 'CAMPAIGN_LIST' | 'CAMPAIGN' | 'INSURANCE' | 'PRODUCT_LIST' | 'CATEGORIES' | 'IMEI_SUBSCRIPTION_PRODUCT' | 'IMEI_DISCOUNT' | 'OFFERING_NEXT_HOUR' | 'CAMPAIGN_NEXT_HOUR',
        cacheId: string,
    ): CancelablePromise<CacheSuccessResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dcse-pe-offering-catalog/cache/clear/{cacheMap}/{cacheId}',
            path: {
                'cacheMap': cacheMap,
                'cacheId': cacheId,
            },
            errors: {
                400: `Bad Request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Clears all cache for a specific offering catalog endpoint.
     * @param cacheMap
     * @returns CacheSuccessResponse Successful operation
     * @throws ApiError
     */
    public static clear2(
        cacheMap: 'OFFERING_LIST' | 'PERSONALIZED_OFFERING_LIST' | 'OFFERING_LIST_FOR_MARTECH' | 'OFFERING' | 'CAMPAIGN_LIST' | 'CAMPAIGN' | 'INSURANCE' | 'PRODUCT_LIST' | 'CATEGORIES' | 'IMEI_SUBSCRIPTION_PRODUCT' | 'IMEI_DISCOUNT' | 'OFFERING_NEXT_HOUR' | 'CAMPAIGN_NEXT_HOUR',
    ): CancelablePromise<CacheSuccessResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dcse-pe-offering-catalog/cache/clear/{cacheMap}/all',
            path: {
                'cacheMap': cacheMap,
            },
            errors: {
                400: `Bad Request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

}
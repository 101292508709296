/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CaseCommentRequest } from '../models/CaseCommentRequest';
import type { CaseCommentResponse } from '../models/CaseCommentResponse';
import type { CaseContactMethodUpdateRequest } from '../models/CaseContactMethodUpdateRequest';
import type { CaseCreateResponse } from '../models/CaseCreateResponse';
import type { CaseDetailsResponse } from '../models/CaseDetailsResponse';
import type { CasesResponse } from '../models/CasesResponse';
import type { CasesWidgetResponse } from '../models/CasesWidgetResponse';
import type { GdprCaseCreateRequest } from '../models/GdprCaseCreateRequest';
import type { IncidentCaseCreateRequest } from '../models/IncidentCaseCreateRequest';
import type { OpenCaseRequest } from '../models/OpenCaseRequest';
import type { QuestionCaseCreateRequest } from '../models/QuestionCaseCreateRequest';
import type { TechnicianCaseCreateRequest } from '../models/TechnicianCaseCreateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CorpCaseService {

    /**
     * Open case
     * @returns CaseDetailsResponse OK
     * @throws ApiError
     */
    public static openCase({
        caseId,
        xAuthScopeId,
        requestBody,
        acceptLanguage,
    }: {
        caseId: string,
        xAuthScopeId: any,
        requestBody: OpenCaseRequest,
        acceptLanguage?: string,
    }): CancelablePromise<CaseDetailsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/cases/{caseId}/open',
            path: {
                'caseId': caseId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Add a comment to Case
     * @returns CaseCommentResponse Created
     * @throws ApiError
     */
    public static addCommentToCase({
        caseId,
        xAuthScopeId,
        requestBody,
    }: {
        caseId: string,
        xAuthScopeId: any,
        requestBody: CaseCommentRequest,
    }): CancelablePromise<CaseCommentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/cases/{caseId}/comments',
            path: {
                'caseId': caseId,
            },
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Handle actions after customer accepts proposed case resolution
     * @returns void
     * @throws ApiError
     */
    public static acceptSolution({
        caseId,
        xAuthScopeId,
    }: {
        caseId: string,
        xAuthScopeId: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/cases/{caseId}/accept-solution',
            path: {
                'caseId': caseId,
            },
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create personal technician case
     * @returns CaseCreateResponse Created
     * @throws ApiError
     */
    public static createTechnicianCase({
        xAuthScopeId,
        requestBody,
    }: {
        xAuthScopeId: any,
        requestBody: TechnicianCaseCreateRequest,
    }): CancelablePromise<CaseCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/cases/technician',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create question case
     * @returns CaseCreateResponse Created
     * @throws ApiError
     */
    public static createQuestionCase({
        xAuthScopeId,
        requestBody,
    }: {
        xAuthScopeId: any,
        requestBody: QuestionCaseCreateRequest,
    }): CancelablePromise<CaseCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/cases/question',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create incident case
     * @returns CaseCreateResponse Created
     * @throws ApiError
     */
    public static createIncidentCase({
        xAuthScopeId,
        requestBody,
    }: {
        xAuthScopeId: any,
        requestBody: IncidentCaseCreateRequest,
    }): CancelablePromise<CaseCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/cases/incident',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create GDPR case
     * @returns CaseCreateResponse Created
     * @throws ApiError
     */
    public static createGdprCase({
        xAuthScopeId,
        requestBody,
    }: {
        xAuthScopeId: any,
        requestBody: GdprCaseCreateRequest,
    }): CancelablePromise<CaseCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/cases/gdpr',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Retrieves details about case
     * @returns CaseDetailsResponse OK
     * @throws ApiError
     */
    public static getCaseDetails({
        acceptLanguage,
        caseId,
        xAuthScopeId,
    }: {
        acceptLanguage: any,
        caseId: string,
        xAuthScopeId: any,
    }): CancelablePromise<CaseDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/cases/{caseId}',
            path: {
                'caseId': caseId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update preferredContactMethod
     * @returns void
     * @throws ApiError
     */
    public static updateCaseContactMethod({
        caseId,
        xAuthScopeId,
        requestBody,
    }: {
        caseId: string,
        xAuthScopeId: any,
        requestBody: CaseContactMethodUpdateRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/cases/{caseId}',
            path: {
                'caseId': caseId,
            },
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get cases for cases widget
     * @returns CasesWidgetResponse OK
     * @throws ApiError
     */
    public static getCasesForWidget({
        xAuthScopeId,
        organizationTscIds,
        statuses,
        sortBy,
        sortDirection,
        size,
    }: {
        xAuthScopeId: any,
        organizationTscIds?: Array<string>,
        statuses?: Array<'NEW' | 'OPEN' | 'AWAITING_INFO' | 'RESOLVED' | 'CLOSED' | 'CANCELLED' | 'UNRECOGNIZED'>,
        /** Attribute to sort cases by. **/
        sortBy?: 'CASE_ID' | 'SHORT_DESCRIPTION' | 'STATUS' | 'CATEGORY' | 'UPDATED_ON' | 'ASSET_ALIAS' | 'CREATED_ON' | 'SUBSCRIPTION_ID' | 'SUBSCRIPTION_NUMBER' | 'REPORTING_PERSON' | 'CUSTOMER_REFERENCE' | 'COUNTRY' | 'CITY' | 'STREET' | 'ORGANIZATION_NUMBER' | 'ORGANIZATION_NAME' | 'SUBSCRIPTION_PRODUCT_NAME' | 'SERVICE_OBJECT_NAME' | 'DESCRIPTION' | 'CLOSE_NOTES' | 'CLOSE_CODE' | 'RESOLVED_ON' | 'DURATION' | 'BUSINESS_ELAPSED_TIME' | 'CLOSED_ON' | 'IMPACT' | 'URGENCY' | 'PRIORITY' | 'GUARANTEED_TIME' | 'CALCULATE_SLA' | 'HAS_BREACHED_SLA' | 'SUBSCRIPTION_STREET' | 'SUBSCRIPTION_CITY' | 'SUBSCRIPTION_COUNTRY' | 'SUBSCRIPTION_ALIAS',
        sortDirection?: 'ASC' | 'DESC',
        size?: number,
    }): CancelablePromise<CasesWidgetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/landing-page-widget/cases',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'organizationTscIds': organizationTscIds,
                'statuses': statuses,
                'sortBy': sortBy,
                'sortDirection': sortDirection,
                'size': size,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get cases
     * @returns CasesResponse OK
     * @throws ApiError
     */
    public static getCases({
        xAuthScopeId,
        acceptLanguage,
        sortBy,
        relatedCaseIds,
        sortDirection,
        size,
        start,
        organizationTscIds,
        statuses,
        categories,
        productCodes,
        excludeCloseCodes,
        createdFrom,
        createdTo,
        resolvedFrom,
        resolvedTo,
        textSearch,
        searchCaseId,
        searchShortDescription,
        searchUpdatedOn,
        searchCreatedOn,
        searchSubscriptionNumber,
        searchReportingPerson,
        searchStatus,
        searchCategory,
        searchSubscriptionAlias,
        searchCountry,
        searchCity,
        searchStreet,
        searchCustomerReference,
        c2BUid,
    }: {
        xAuthScopeId: any,
        acceptLanguage?: any,
        /** Attribute to sort cases by. **/
        sortBy?: 'CASE_ID' | 'SHORT_DESCRIPTION' | 'STATUS' | 'CATEGORY' | 'UPDATED_ON' | 'ASSET_ALIAS' | 'CREATED_ON' | 'SUBSCRIPTION_ID' | 'SUBSCRIPTION_NUMBER' | 'REPORTING_PERSON' | 'CUSTOMER_REFERENCE' | 'COUNTRY' | 'CITY' | 'STREET' | 'ORGANIZATION_NUMBER' | 'ORGANIZATION_NAME' | 'SUBSCRIPTION_PRODUCT_NAME' | 'SERVICE_OBJECT_NAME' | 'DESCRIPTION' | 'CLOSE_NOTES' | 'CLOSE_CODE' | 'RESOLVED_ON' | 'DURATION' | 'BUSINESS_ELAPSED_TIME' | 'CLOSED_ON' | 'IMPACT' | 'URGENCY' | 'PRIORITY' | 'GUARANTEED_TIME' | 'CALCULATE_SLA' | 'HAS_BREACHED_SLA' | 'SUBSCRIPTION_STREET' | 'SUBSCRIPTION_CITY' | 'SUBSCRIPTION_COUNTRY' | 'SUBSCRIPTION_ALIAS',
        relatedCaseIds?: Array<string>,
        sortDirection?: 'ASC' | 'DESC',
        size?: number,
        start?: number,
        organizationTscIds?: Array<string>,
        statuses?: Array<'NEW' | 'OPEN' | 'AWAITING_INFO' | 'RESOLVED' | 'CLOSED' | 'CANCELLED' | 'UNRECOGNIZED'>,
        categories?: Array<'GDPR' | 'INCIDENT' | 'SERVICE_REQUEST' | 'INVOICE' | 'PERSONAL_TECHNICIAN' | 'USER_SUPPORT' | 'FEEDBACK' | 'DELIVERY' | 'ORDERING' | 'LEDGER' | 'UNRECOGNIZED'>,
        productCodes?: Array<string>,
        excludeCloseCodes?: Array<string>,
        /** Date formats: YYYY-MM-DD **/
        createdFrom?: string,
        /** Date formats: YYYY-MM-DD **/
        createdTo?: string,
        /** Date formats: YYYY-MM-DD **/
        resolvedFrom?: string,
        /** Date formats: YYYY-MM-DD **/
        resolvedTo?: string,
        /** String for searching cases by keyword or part of a keyword. The search is performed on the following attributes: caseId, shortDescription and subscriptionId. **/
        textSearch?: string,
        searchCaseId?: string,
        searchShortDescription?: string,
        searchUpdatedOn?: string,
        searchCreatedOn?: string,
        searchSubscriptionNumber?: string,
        searchReportingPerson?: string,
        searchStatus?: string,
        searchCategory?: 'GDPR' | 'INCIDENT' | 'SERVICE_REQUEST' | 'INVOICE' | 'PERSONAL_TECHNICIAN' | 'USER_SUPPORT' | 'FEEDBACK' | 'DELIVERY' | 'ORDERING' | 'LEDGER' | 'UNRECOGNIZED',
        searchSubscriptionAlias?: string,
        searchCountry?: string,
        searchCity?: string,
        searchStreet?: string,
        searchCustomerReference?: string,
        c2BUid?: string,
    }): CancelablePromise<CasesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/cases',
            headers: {
                'Accept-Language': acceptLanguage,
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'sortBy': sortBy,
                'relatedCaseIds': relatedCaseIds,
                'sortDirection': sortDirection,
                'size': size,
                'start': start,
                'organizationTscIds': organizationTscIds,
                'statuses': statuses,
                'categories': categories,
                'productCodes': productCodes,
                'excludeCloseCodes': excludeCloseCodes,
                'createdFrom': createdFrom,
                'createdTo': createdTo,
                'resolvedFrom': resolvedFrom,
                'resolvedTo': resolvedTo,
                'textSearch': textSearch,
                'search.caseId': searchCaseId,
                'search.shortDescription': searchShortDescription,
                'search.updatedOn': searchUpdatedOn,
                'search.createdOn': searchCreatedOn,
                'search.subscriptionNumber': searchSubscriptionNumber,
                'search.reportingPerson': searchReportingPerson,
                'search.status': searchStatus,
                'search.category': searchCategory,
                'search.subscriptionAlias': searchSubscriptionAlias,
                'search.country': searchCountry,
                'search.city': searchCity,
                'search.street': searchStreet,
                'search.customerReference': searchCustomerReference,
                'c2bUid': c2BUid,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

}
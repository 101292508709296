/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressLookupRequest } from '../models/AddressLookupRequest';
import type { AddressSuccessResponse } from '../models/AddressSuccessResponse';
import type { CitySuccessResponse } from '../models/CitySuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AddressLookupV2Service {

    /**
     * @deprecated
     * Fetch addresses from AddressMaster matching the address search string
     * @param requestBody
     * @returns AddressSuccessResponse Everything went as expected.
     * @throws ApiError
     */
    public static addressLookupV2(
        requestBody: AddressLookupRequest,
    ): CancelablePromise<AddressSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `A request parameter is missing and/or has faulty formatting.`,
                500: `Unexpected error, most likely due to failed AddressMaster DB lookup.`,
            },
        });
    }

    /**
     * Fetch cities from AddressMaster matching the search string
     * @param requestBody
     * @returns CitySuccessResponse Everything went as expected.
     * @throws ApiError
     */
    public static getCities(
        requestBody: AddressLookupRequest,
    ): CancelablePromise<CitySuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/cities',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `A request parameter is missing and/or has faulty formatting.`,
                500: `Unexpected error, most likely due to failed AddressMaster DB lookup.`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderBulkSimRequestBody } from '../models/OrderBulkSimRequestBody';
import type { OrderBulkSimResponse } from '../models/OrderBulkSimResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BulkSimOrderControllerService {

    /**
     * Order Bulk SIM
     * @param body body
     * @param scopeId scopeId
     * @returns OrderBulkSimResponse Successful request
     * @throws ApiError
     */
    public static orderBulkSimUsingPost(
        body: OrderBulkSimRequestBody,
        scopeId: string,
    ): CancelablePromise<OrderBulkSimResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/services/corp/bulk-sim-order/v1.0.0/{scopeId}/order',
            path: {
                'scopeId': scopeId,
            },
            body: body,
        });
    }

}
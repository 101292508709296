/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BankIdInvocationResponse } from '../models/BankIdInvocationResponse';
import type { BankIdStatusResponse } from '../models/BankIdStatusResponse';
import type { GenericUser } from '../models/GenericUser';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LoginWithBankIdService {

    /**
     * Initiate login without bank id (only working in test environment)
     * @param genericUser genericUser
     * @returns BankIdInvocationResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static getBankIdMockInvocationIdUsingPost(
        genericUser: GenericUser,
    ): CancelablePromise<BankIdInvocationResponse | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/springfield-login/login/bankid',
            body: genericUser,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Returns the status of the login wihout bank id (only working in test environment)
     * @param invocationId invocationId
     * @returns BankIdStatusResponse OK
     * @throws ApiError
     */
    public static getBankIdMockStatusUsingGet(
        invocationId: string,
    ): CancelablePromise<BankIdStatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/springfield-login/login/bankid/status/{invocationId}',
            path: {
                'invocationId': invocationId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Initiate login with bank id
     * @param genericUser genericUser
     * @returns BankIdInvocationResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static getBankIdInvocationIdUsingPost(
        genericUser: GenericUser,
    ): CancelablePromise<BankIdInvocationResponse | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/springfield-login/login/bankidtest',
            body: genericUser,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get the status for the login
     * @param invocationId invocationId
     * @returns BankIdStatusResponse OK
     * @throws ApiError
     */
    public static getBankIdStatusUsingGet(
        invocationId: string,
    ): CancelablePromise<BankIdStatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/springfield-login/login/bankidtest/status/{invocationId}',
            path: {
                'invocationId': invocationId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
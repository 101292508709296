/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CryptoRequest } from '../models/CryptoRequest';
import type { CryptoResponse } from '../models/CryptoResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * @param body
     * @returns CryptoResponse successful operation
     * @throws ApiError
     */
    public static decrypt(
        body?: CryptoRequest,
    ): CancelablePromise<CryptoResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/decrypt',
            body: body,
        });
    }

    /**
     * @param body
     * @returns CryptoResponse successful operation
     * @throws ApiError
     */
    public static encrypt(
        body?: CryptoRequest,
    ): CancelablePromise<CryptoResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/encrypt',
            body: body,
        });
    }

}
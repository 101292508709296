/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddonOrderRequest } from '../models/AddonOrderRequest';
import type { CheckoutRequest } from '../models/CheckoutRequest';
import type { CheckoutResponseWrapper } from '../models/CheckoutResponseWrapper';
import type { DisconnectRequest } from '../models/DisconnectRequest';
import type { FixedModifyRequest } from '../models/FixedModifyRequest';
import type { TransferAssetOperationRequest } from '../models/TransferAssetOperationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class V4Service {

    /**
     * Placing an order
     * @param requestBody
     * @returns CheckoutResponseWrapper Successful operation
     * @throws ApiError
     */
    public static order1(
        requestBody: CheckoutRequest,
    ): CancelablePromise<CheckoutResponseWrapper> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v4/checkout',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Transfer ownership of asset(s). Only possible to transfer entire promotions.
     * @param requestBody
     * @returns CheckoutResponseWrapper Successful operation
     * @throws ApiError
     */
    public static transfer1(
        requestBody: TransferAssetOperationRequest,
    ): CancelablePromise<CheckoutResponseWrapper> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v4/checkout/transfer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Modify operation on fixed services (broadband, tv, voip)
     * @param requestBody
     * @returns CheckoutResponseWrapper Successful operation
     * @throws ApiError
     */
    public static fixedModify1(
        requestBody: FixedModifyRequest,
    ): CancelablePromise<CheckoutResponseWrapper> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v4/checkout/fixedmodify',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Disconnecting asset(s). Main subscription id will disconnect entire promotion, while supplementary line id will only disconnect supplementary line.
     * @param requestBody
     * @returns CheckoutResponseWrapper Successful operation
     * @throws ApiError
     */
    public static disconnect1(
        requestBody: DisconnectRequest,
    ): CancelablePromise<CheckoutResponseWrapper> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v4/checkout/disconnect',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Addon operation on existing subscriptions. Possibility to add, update or delete addons on existing asset
     * @param requestBody
     * @returns CheckoutResponseWrapper Successful operation
     * @throws ApiError
     */
    public static addons1(
        requestBody: AddonOrderRequest,
    ): CancelablePromise<CheckoutResponseWrapper> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v4/checkout/addons',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Activate addon on existing mobile subscription. Only activation of single service through single msisdn per call
     * @param msisdn
     * @param productSubcategory
     * @returns CheckoutResponseWrapper Successful operation
     * @throws ApiError
     */
    public static activateMobileAddon1(
        msisdn: string,
        productSubcategory: string,
    ): CancelablePromise<CheckoutResponseWrapper> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v4/checkout/addons/{msisdn}/{productSubcategory}',
            path: {
                'msisdn': msisdn,
                'productSubcategory': productSubcategory,
            },
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Unexpected error`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetServiceWebsOnUserResponseDTO } from '../models/GetServiceWebsOnUserResponseDTO';
import type { UpdateServiceWebAttributesRequestDTO } from '../models/UpdateServiceWebAttributesRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CsrUsersServiceWebsControllerService {

    /**
     * Get service web profiles for a specific user on a group
     * #token.hasAccess('TA_VIEW_CUSTOMERS')
     * @param groupId Customer Group ID
     * @param tcwssId TCWSS ID
     * @returns GetServiceWebsOnUserResponseDTO OK
     * @throws ApiError
     */
    public static getServiceWebProfilesOnUserUsingGet(
        groupId?: number,
        tcwssId?: string,
    ): CancelablePromise<GetServiceWebsOnUserResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/csr/customergroups/{groupId}/users/{tcwssId}/servicewebprofiles',
            path: {
                'groupId': groupId,
                'tcwssId': tcwssId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update service web profile attributes for IN_AHS SW for a specific user on a group
     * #token.hasAccess('TA_EDIT_CUSTOMERS')
     * @param updateServiceWebAttributesRequestDto updateServiceWebAttributesRequestDTO
     * @param groupId Customer Group ID
     * @param tcwssId TCWSS ID
     * @returns any OK
     * @throws ApiError
     */
    public static updateInAhsServiceWebProfileOnUserUsingPut(
        updateServiceWebAttributesRequestDto: UpdateServiceWebAttributesRequestDTO,
        groupId?: number,
        tcwssId?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/corp-sra-service/public/csr/customergroups/{groupId}/users/{tcwssId}/servicewebprofiles/IN_AHS',
            path: {
                'groupId': groupId,
                'tcwssId': tcwssId,
            },
            body: updateServiceWebAttributesRequestDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update service web profile attributes for SurfBlaster for a specific user on a group
     * #token.hasAccess('TA_EDIT_CUSTOMERS')
     * @param updateServiceWebAttributesRequestDto updateServiceWebAttributesRequestDTO
     * @param groupId Customer Group ID
     * @param tcwssId TCWSS ID
     * @returns any OK
     * @throws ApiError
     */
    public static updateSurfBlasterServiceWebProfileOnUserUsingPut(
        updateServiceWebAttributesRequestDto: UpdateServiceWebAttributesRequestDTO,
        groupId?: number,
        tcwssId?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/corp-sra-service/public/csr/customergroups/{groupId}/users/{tcwssId}/servicewebprofiles/SURFBLASTER',
            path: {
                'groupId': groupId,
                'tcwssId': tcwssId,
            },
            body: updateServiceWebAttributesRequestDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update service web profile attributes for a service web for a specific user on a group
     * #token.hasAccess('TA_EDIT_CUSTOMERS')
     * @param updateServiceWebAttributesRequestDto updateServiceWebAttributesRequestDTO
     * @param groupId Customer Group ID
     * @param serviceWeb Service Web ID
     * @param tcwssId TCWSS ID
     * @returns any OK
     * @throws ApiError
     */
    public static updateServiceWebProfileOnUserUsingPut(
        updateServiceWebAttributesRequestDto: UpdateServiceWebAttributesRequestDTO,
        groupId?: number,
        serviceWeb?: string,
        tcwssId?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/corp-sra-service/public/csr/customergroups/{groupId}/users/{tcwssId}/servicewebprofiles/{serviceWeb}',
            path: {
                'groupId': groupId,
                'serviceWeb': serviceWeb,
                'tcwssId': tcwssId,
            },
            body: updateServiceWebAttributesRequestDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
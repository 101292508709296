/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetOrganizationResponse } from '../models/GetOrganizationResponse';
import type { SearchOrganizationsSearchResultDTO } from '../models/SearchOrganizationsSearchResultDTO';
import type { SearchResultsDTO } from '../models/SearchResultsDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PublicSearchControllerService {

    /**
     * getOrganizationByOrganizationNumber
     * @param organizationnumber organizationnumber
     * @returns GetOrganizationResponse OK
     * @throws ApiError
     */
    public static getOrganizationByOrganizationNumberUsingGet(
        organizationnumber: string,
    ): CancelablePromise<GetOrganizationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-customer-group-search/public/organizations',
            query: {
                'organizationnumber': organizationnumber,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * searchOrganizations
     * @param query query
     * @returns SearchOrganizationsSearchResultDTO OK
     * @throws ApiError
     */
    public static searchOrganizationsUsingGet(
        query: string,
    ): CancelablePromise<SearchOrganizationsSearchResultDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-customer-group-search/public/organizations/search',
            query: {
                'query': query,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * search
     * @param query query
     * @returns SearchResultsDTO OK
     * @throws ApiError
     */
    public static searchUsingGet1(
        query: string,
    ): CancelablePromise<SearchResultsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-customer-group-search/public/search',
            query: {
                'query': query,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
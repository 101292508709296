/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SegmentRequestDto } from '../models/SegmentRequestDto';
import type { SegmentResponseDto } from '../models/SegmentResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TeliaAdminService {

    /**
     * Get segment on a CustomerGroup by ScopeId
     * @param scopeId Customer identification number
     * @returns SegmentResponseDto Success
     * @throws ApiError
     */
    public static getSegment(
        scopeId: string,
    ): CancelablePromise<SegmentResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/teliaadmin/scope/{scopeId}/segment',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Update segment setting on a company
     * @param scopeId Customer identification number
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateSegment(
        scopeId: string,
        requestBody: SegmentRequestDto,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/teliaadmin/scope/{scopeId}/segment',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductByUrlSlugSuccessResponse } from '../models/ProductByUrlSlugSuccessResponse';
import type { ProductRequest } from '../models/ProductRequest';
import type { ProductSuccessResponse } from '../models/ProductSuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalServiceForReadingRawProductDataFromProductEditorService {

    /**
     * Read raw product data.
     * @param requestBody
     * @returns ProductSuccessResponse Successful operation
     * @throws ApiError
     */
    public static getProducts(
        requestBody: ProductRequest,
    ): CancelablePromise<ProductSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dcse-pe-offering-catalog/products/v1',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Decrement productCounter for these products
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static decrementProductCounters(
        requestBody: Array<number>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dcse-pe-offering-catalog/products/decrement-counter',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Could not decrement at least one product counter`,
                410: `Gone`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param urlSlug
     * @returns ProductByUrlSlugSuccessResponse Successful operation
     * @throws ApiError
     */
    public static getProductsByUrlSlug(
        urlSlug: string,
    ): CancelablePromise<ProductByUrlSlugSuccessResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dcse-pe-offering-catalog/products/v1/{url-slug}',
            path: {
                'url-slug': urlSlug,
            },
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

}
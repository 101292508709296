/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddOrganizationRequestDTO } from '../models/AddOrganizationRequestDTO';
import type { AddOrganizationResponseDTO } from '../models/AddOrganizationResponseDTO';
import type { CreateCustomerGroupRequestDTO1 } from '../models/CreateCustomerGroupRequestDTO1';
import type { CreateCustomerGroupResponseDTO } from '../models/CreateCustomerGroupResponseDTO';
import type { CreateCustomerGroupWithOrganizationRequest } from '../models/CreateCustomerGroupWithOrganizationRequest';
import type { CreateCustomerGroupWithOrganizationResponse } from '../models/CreateCustomerGroupWithOrganizationResponse';
import type { GetUsersRequestDTO } from '../models/GetUsersRequestDTO';
import type { ResponseEntity } from '../models/ResponseEntity';
import type { SetCustomerGroupNameRequestDTO } from '../models/SetCustomerGroupNameRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CsrCustomerGroupControllerService {

    /**
     * createCustomerGroup
     * #token.hasAccess('TA_EDIT_CUSTOMERS')
     * @param createCustomerGroupRequestDto createCustomerGroupRequestDTO
     * @returns CreateCustomerGroupResponseDTO OK
     * @returns any Created
     * @throws ApiError
     */
    public static createCustomerGroupUsingPost(
        createCustomerGroupRequestDto: CreateCustomerGroupRequestDTO1,
    ): CancelablePromise<CreateCustomerGroupResponseDTO | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-sra-service/public/csr/customergroups',
            body: createCustomerGroupRequestDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * createCustomerGroupWithOrganization
     * #token.hasAccess('TA_EDIT_CUSTOMERS')
     * @param createCustomerGroupRequestDto createCustomerGroupRequestDTO
     * @returns CreateCustomerGroupWithOrganizationResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static createCustomerGroupWithOrganizationUsingPost(
        createCustomerGroupRequestDto: CreateCustomerGroupWithOrganizationRequest,
    ): CancelablePromise<CreateCustomerGroupWithOrganizationResponse | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-sra-service/public/csr/customergroups/createwithorganization',
            body: createCustomerGroupRequestDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * deleteCustomerGroup
     * #token.hasAccess('TA_DELETE_CUSTOMERS')
     * @param groupId groupId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteCustomerGroupUsingDelete(
        groupId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/corp-sra-service/public/csr/customergroups/{groupId}',
            path: {
                'groupId': groupId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * setCustomerGroupName
     * #token.hasAccess('TA_EDIT_CUSTOMERS')
     * @param groupId groupId
     * @param requestDto requestDTO
     * @returns any OK
     * @throws ApiError
     */
    public static setCustomerGroupNameUsingPut(
        groupId: number,
        requestDto: SetCustomerGroupNameRequestDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/corp-sra-service/public/csr/customergroups/{groupId}/name',
            path: {
                'groupId': groupId,
            },
            body: requestDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * addOrganization
     * #token.hasAccess('TA_EDIT_CUSTOMERS')
     * @param addOrganizationRequestDto addOrganizationRequestDTO
     * @param groupId groupId
     * @returns AddOrganizationResponseDTO OK
     * @returns any Created
     * @throws ApiError
     */
    public static addOrganizationUsingPut(
        addOrganizationRequestDto: AddOrganizationRequestDTO,
        groupId: number,
    ): CancelablePromise<AddOrganizationResponseDTO | any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/corp-sra-service/public/csr/customergroups/{groupId}/organizations',
            path: {
                'groupId': groupId,
            },
            body: addOrganizationRequestDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * deleteOrganization
     * #token.hasAccess('TA_EDIT_CUSTOMERS')
     * @param groupId groupId
     * @param tscid tscid
     * @returns ResponseEntity OK
     * @throws ApiError
     */
    public static deleteOrganizationUsingDelete(
        groupId: number,
        tscid: string,
    ): CancelablePromise<ResponseEntity> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/corp-sra-service/public/csr/customergroups/{groupId}/organizations/{tscid}',
            path: {
                'groupId': groupId,
                'tscid': tscid,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * getUsers
     * #token.hasAccess('TA_VIEW_CUSTOMERS')
     * @param groupId groupId
     * @returns GetUsersRequestDTO OK
     * @throws ApiError
     */
    public static getUsersUsingGet1(
        groupId: number,
    ): CancelablePromise<GetUsersRequestDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/csr/customergroups/{groupId}/users',
            path: {
                'groupId': groupId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeSuperUserRequestDTO } from '../models/ChangeSuperUserRequestDTO';
import type { GetIsFederatedResponseDTO } from '../models/GetIsFederatedResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MyBusinessCustomerGroupsControllerService {

    /**
     * Check if group is federated
     * #token.isAuthenticated()
     * @param groupId groupId
     * @returns GetIsFederatedResponseDTO OK
     * @throws ApiError
     */
    public static isFederatedUsingGet(
        groupId: number,
    ): CancelablePromise<GetIsFederatedResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/isfederated',
            path: {
                'groupId': groupId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * changeSuperUser
     * #userToken.hasAccess('SET_SUPER_USER')
     * @param changeSuperUserRequestDto changeSuperUserRequestDTO
     * @param groupId groupId
     * @returns any OK
     * @throws ApiError
     */
    public static changeSuperUserUsingPut(
        changeSuperUserRequestDto: ChangeSuperUserRequestDTO,
        groupId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/superuser',
            path: {
                'groupId': groupId,
            },
            body: changeSuperUserRequestDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
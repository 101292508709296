/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TechnicianCaseCreateRequestPublic } from '../models/TechnicianCaseCreateRequestPublic';
import type { TechnicianCaseCreateResponse } from '../models/TechnicianCaseCreateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CorpCasePublicService {

    /**
     * Create personal technician case
     * @returns TechnicianCaseCreateResponse Created
     * @throws ApiError
     */
    public static createTechnicianCase1({
        requestBody,
    }: {
        requestBody: TechnicianCaseCreateRequestPublic,
    }): CancelablePromise<TechnicianCaseCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/v1/cases/technician',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

}
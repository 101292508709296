/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListVpnsResponseDTO } from '../models/ListVpnsResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MyBusinessVpnControllerService {

    /**
     * Get all VPNs that an AccessProfile has access to.
     * #token.hasAccess('MANAGE_USER')
     * @param groupId Group ID
     * @returns ListVpnsResponseDTO OK
     * @throws ApiError
     */
    public static getVpnsOnAccessProfileUsingGet(
        groupId?: number,
    ): CancelablePromise<ListVpnsResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/vpns',
            path: {
                'groupId': groupId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetImitationInfoResponseDTO } from '../models/GetImitationInfoResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CsrImitationInfoControllerService {

    /**
     * Get information about user owning the access profile being imitated
     * #token.hasAccess('TA_IMITATE_ACCESSPROFILE')
     * @param accessProfileId Access Profile ID
     * @returns GetImitationInfoResponseDTO OK
     * @throws ApiError
     */
    public static getImitationInfoUsingGet(
        accessProfileId?: number,
    ): CancelablePromise<GetImitationInfoResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/csr/accessprofiles/{accessProfileId}/imitate-info',
            path: {
                'accessProfileId': accessProfileId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
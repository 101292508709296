/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReadSubscriptionRequest } from '../models/ReadSubscriptionRequest';
import type { ReadSubscriptionSuccessResponse } from '../models/ReadSubscriptionSuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class V1Service {

    /**
     * Returns a list of C2BCache subscriptions for a logged in customer.
     * @param requestBody
     * @returns ReadSubscriptionSuccessResponse Successful operation.
     * @throws ApiError
     */
    public static readSubscription(
        requestBody: ReadSubscriptionRequest,
    ): CancelablePromise<ReadSubscriptionSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The consumer of this service did something wrong.`,
                401: `User is not logged in.`,
                410: `Gone`,
                500: `The service or another system that it depends on did something wrong.`,
            },
        });
    }

}
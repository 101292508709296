/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerGroupWithAccessDTO } from '../models/CustomerGroupWithAccessDTO';
import type { GetCustomerGroupResponseDTO1 } from '../models/GetCustomerGroupResponseDTO1';
import type { StructureSearchResultDTO } from '../models/StructureSearchResultDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MyBusinessStructureControllerService {

    /**
     * Get a Customer Group
     * #token.hasAccess('MANAGE_USER')
     * @param groupId Customer Group ID
     * @returns GetCustomerGroupResponseDTO1 OK
     * @throws ApiError
     */
    public static getCustomerGroupUsingGet1(
        groupId?: number,
    ): CancelablePromise<GetCustomerGroupResponseDTO1> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}',
            path: {
                'groupId': groupId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get a Customer Group with information about what parts a user have access to
     * #token.hasAccess('MANAGE_USER')
     * @param groupId Customer Group ID
     * @param tcwssid Include access restrictions for user by TCWSSID
     * @returns CustomerGroupWithAccessDTO OK
     * @throws ApiError
     */
    public static getCustomerGroupForUserUsingGet(
        groupId?: number,
        tcwssid?: string,
    ): CancelablePromise<CustomerGroupWithAccessDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/access/{tcwssid}',
            path: {
                'groupId': groupId,
                'tcwssid': tcwssid,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * List/search for cost centers in all organizations in customer group filtered by access profile
     * #token.hasAccess('MANAGE_USER')
     * @param groupId Customer Group ID
     * @param search Search term (leave empty for all)
     * @returns StructureSearchResultDTO OK
     * @throws ApiError
     */
    public static searchCostCentersUsingGet(
        groupId: number,
        search?: string,
    ): CancelablePromise<StructureSearchResultDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/costcenters',
            path: {
                'groupId': groupId,
            },
            query: {
                'search': search,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * List/search for organizations in customer group
     * #token.hasAccess('MANAGE_USER')
     * @param groupId Customer Group ID
     * @param search Search term (leave empty for all)
     * @returns StructureSearchResultDTO OK
     * @throws ApiError
     */
    public static searchOrganizationsUsingGet(
        groupId: number,
        search?: string,
    ): CancelablePromise<StructureSearchResultDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/organizations',
            path: {
                'groupId': groupId,
            },
            query: {
                'search': search,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * List/search for units in all organizations in customer group filtered by access profile
     * #token.hasAccess('MANAGE_USER')
     * @param groupId Customer Group ID
     * @param search Search term (leave empty for all)
     * @returns StructureSearchResultDTO OK
     * @throws ApiError
     */
    public static searchUnitsUsingGet(
        groupId: number,
        search?: string,
    ): CancelablePromise<StructureSearchResultDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/units',
            path: {
                'groupId': groupId,
            },
            query: {
                'search': search,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
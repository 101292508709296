/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InsuranceRequest } from '../models/InsuranceRequest';
import type { InsuranceSuccessResponse } from '../models/InsuranceSuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ServiceForReadingInsuranceDataFromProductEditorService {

    /**
     * Read insurances for given products in offerings.
     * @param requestBody
     * @returns InsuranceSuccessResponse Successful operation
     * @throws ApiError
     */
    public static getInsurance(
        requestBody: Array<InsuranceRequest>,
    ): CancelablePromise<InsuranceSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dcse-pe-offering-catalog/insurance/v1',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

}
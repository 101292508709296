/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResponseWrapperPublicResponse } from '../models/ResponseWrapperPublicResponse';
import type { RetentionRequest } from '../models/RetentionRequest';
import type { VerificationRequest } from '../models/VerificationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class V3Service {

    /**
     * Verify a retention record with pinCode.
     * @param requestBody
     * @returns ResponseWrapperPublicResponse OK
     * @throws ApiError
     */
    public static verify(
        requestBody: VerificationRequest,
    ): CancelablePromise<ResponseWrapperPublicResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/public/verification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Generate a retention record. Record contains information about subscription change consequence, verification status among other things.
     * @param requestBody
     * @returns ResponseWrapperPublicResponse OK
     * @throws ApiError
     */
    public static create(
        requestBody: RetentionRequest,
    ): CancelablePromise<ResponseWrapperPublicResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/public/retention',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                410: `Gone`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderInformationResponseOrderInformationData } from '../models/OrderInformationResponseOrderInformationData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class V1Service {

    /**
     * @param bestId
     * @returns OrderInformationResponseOrderInformationData OK
     * @throws ApiError
     */
    public static getOrderInformation1(
        bestId: number,
    ): CancelablePromise<OrderInformationResponseOrderInformationData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/order/{bestId}',
            path: {
                'bestId': bestId,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ShoppingCartRequestV1 } from '../models/ShoppingCartRequestV1';
import type { ValidateResponseWrapperV1 } from '../models/ValidateResponseWrapperV1';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class V1Service {

    /**
     * @param requestBody
     * @returns ValidateResponseWrapperV1 OK
     * @throws ApiError
     */
    public static validate1(
        requestBody: ShoppingCartRequestV1,
    ): CancelablePromise<ValidateResponseWrapperV1> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/validate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
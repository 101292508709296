/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MobileDeviceSearchSuccessResponse } from '../models/MobileDeviceSearchSuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RestEndpointsForSearchOfMobileDeviceModelsService {

    /**
     * Search mobile device by name
     * @param query
     * @returns MobileDeviceSearchSuccessResponse successful operation
     * @throws ApiError
     */
    public static search(
        query?: string,
    ): CancelablePromise<MobileDeviceSearchSuccessResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/search',
            query: {
                'query': query,
            },
        });
    }

}
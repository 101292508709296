/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SearchResultsDTO } from '../models/SearchResultsDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PrivateSearchControllerService {

    /**
     * search
     * @param query query
     * @returns SearchResultsDTO OK
     * @throws ApiError
     */
    public static searchUsingGet(
        query: string,
    ): CancelablePromise<SearchResultsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-customer-group-search/private/search',
            query: {
                'query': query,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
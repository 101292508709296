/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SlaReportCasesResponse } from '../models/SlaReportCasesResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SlaReportCasesService {

    /**
     * Get SLA report cases
     * @returns SlaReportCasesResponse OK
     * @throws ApiError
     */
    public static getSlaReportCases({
        xAuthScopeId,
        tscId,
        createdFrom,
        createdTo,
        resolvedFrom,
        resolvedTo,
        sortBy,
        sortDirection,
        size,
        start,
        searchCaseId,
        searchCustomerReference,
        searchOrganizationNumber,
        searchOrganizationName,
        searchSubscriptionProductName,
        searchSubscriptionId,
        searchSubscriptionNumber,
        searchServiceObjectName,
        searchSubscriptionAlias,
        searchSubscriptionStreet,
        searchSubscriptionCity,
        searchSubscriptionCountry,
        searchShortDescription,
        searchDescription,
        searchCloseNotes,
        searchCloseCode,
        searchCreatedOn,
        searchResolvedOn,
        searchDuration,
        searchBusinessElapsedTime,
        searchClosedOn,
        searchGuaranteedTime,
        searchReportingPerson,
        searchImpact,
        searchUrgency,
        searchPriority,
        searchCalculateSla,
        searchHasBreachedSla,
    }: {
        xAuthScopeId: any,
        tscId?: string,
        /** Date formats: YYYY-MM-DD **/
        createdFrom?: string,
        /** Date formats: YYYY-MM-DD **/
        createdTo?: string,
        /** Date formats: YYYY-MM-DD **/
        resolvedFrom?: string,
        /** Date formats: YYYY-MM-DD **/
        resolvedTo?: string,
        sortBy?: string,
        sortDirection?: 'ASC' | 'DESC',
        size?: number,
        start?: number,
        searchCaseId?: string,
        searchCustomerReference?: string,
        searchOrganizationNumber?: string,
        searchOrganizationName?: string,
        searchSubscriptionProductName?: string,
        searchSubscriptionId?: string,
        searchSubscriptionNumber?: string,
        searchServiceObjectName?: string,
        searchSubscriptionAlias?: string,
        searchSubscriptionStreet?: string,
        searchSubscriptionCity?: string,
        searchSubscriptionCountry?: string,
        searchShortDescription?: string,
        searchDescription?: string,
        searchCloseNotes?: string,
        searchCloseCode?: string,
        searchCreatedOn?: string,
        searchResolvedOn?: string,
        searchDuration?: string,
        searchBusinessElapsedTime?: string,
        searchClosedOn?: string,
        searchGuaranteedTime?: string,
        searchReportingPerson?: string,
        searchImpact?: number,
        searchUrgency?: number,
        searchPriority?: number,
        searchCalculateSla?: boolean,
        searchHasBreachedSla?: boolean,
    }): CancelablePromise<SlaReportCasesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/sla-report-cases',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'tscId': tscId,
                'createdFrom': createdFrom,
                'createdTo': createdTo,
                'resolvedFrom': resolvedFrom,
                'resolvedTo': resolvedTo,
                'sortBy': sortBy,
                'sortDirection': sortDirection,
                'size': size,
                'start': start,
                'search.caseId': searchCaseId,
                'search.customerReference': searchCustomerReference,
                'search.organizationNumber': searchOrganizationNumber,
                'search.organizationName': searchOrganizationName,
                'search.subscriptionProductName': searchSubscriptionProductName,
                'search.subscriptionId': searchSubscriptionId,
                'search.subscriptionNumber': searchSubscriptionNumber,
                'search.serviceObjectName': searchServiceObjectName,
                'search.subscriptionAlias': searchSubscriptionAlias,
                'search.subscriptionStreet': searchSubscriptionStreet,
                'search.subscriptionCity': searchSubscriptionCity,
                'search.subscriptionCountry': searchSubscriptionCountry,
                'search.shortDescription': searchShortDescription,
                'search.description': searchDescription,
                'search.closeNotes': searchCloseNotes,
                'search.closeCode': searchCloseCode,
                'search.createdOn': searchCreatedOn,
                'search.resolvedOn': searchResolvedOn,
                'search.duration': searchDuration,
                'search.businessElapsedTime': searchBusinessElapsedTime,
                'search.closedOn': searchClosedOn,
                'search.guaranteedTime': searchGuaranteedTime,
                'search.reportingPerson': searchReportingPerson,
                'search.impact': searchImpact,
                'search.urgency': searchUrgency,
                'search.priority': searchPriority,
                'search.calculateSla': searchCalculateSla,
                'search.hasBreachedSla': searchHasBreachedSla,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Export SLA report cases
     * @returns string OK
     * @throws ApiError
     */
    public static exportSlaReportCases({
        xAuthScopeId,
        acceptLanguage,
        tscId,
        createdFrom,
        createdTo,
        resolvedFrom,
        resolvedTo,
        sortBy,
        sortDirection,
        size,
        start,
        searchCaseId,
        searchCustomerReference,
        searchOrganizationNumber,
        searchOrganizationName,
        searchSubscriptionProductName,
        searchSubscriptionId,
        searchSubscriptionNumber,
        searchServiceObjectName,
        searchSubscriptionAlias,
        searchSubscriptionStreet,
        searchSubscriptionCity,
        searchSubscriptionCountry,
        searchShortDescription,
        searchDescription,
        searchCloseNotes,
        searchCloseCode,
        searchCreatedOn,
        searchResolvedOn,
        searchDuration,
        searchBusinessElapsedTime,
        searchClosedOn,
        searchGuaranteedTime,
        searchReportingPerson,
        searchImpact,
        searchUrgency,
        searchPriority,
        searchCalculateSla,
        searchHasBreachedSla,
    }: {
        xAuthScopeId: any,
        acceptLanguage: any,
        tscId?: string,
        /** Date formats: YYYY-MM-DD **/
        createdFrom?: string,
        /** Date formats: YYYY-MM-DD **/
        createdTo?: string,
        /** Date formats: YYYY-MM-DD **/
        resolvedFrom?: string,
        /** Date formats: YYYY-MM-DD **/
        resolvedTo?: string,
        sortBy?: string,
        sortDirection?: 'ASC' | 'DESC',
        size?: number,
        start?: number,
        searchCaseId?: string,
        searchCustomerReference?: string,
        searchOrganizationNumber?: string,
        searchOrganizationName?: string,
        searchSubscriptionProductName?: string,
        searchSubscriptionId?: string,
        searchSubscriptionNumber?: string,
        searchServiceObjectName?: string,
        searchSubscriptionAlias?: string,
        searchSubscriptionStreet?: string,
        searchSubscriptionCity?: string,
        searchSubscriptionCountry?: string,
        searchShortDescription?: string,
        searchDescription?: string,
        searchCloseNotes?: string,
        searchCloseCode?: string,
        searchCreatedOn?: string,
        searchResolvedOn?: string,
        searchDuration?: string,
        searchBusinessElapsedTime?: string,
        searchClosedOn?: string,
        searchGuaranteedTime?: string,
        searchReportingPerson?: string,
        searchImpact?: number,
        searchUrgency?: number,
        searchPriority?: number,
        searchCalculateSla?: boolean,
        searchHasBreachedSla?: boolean,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/sla-report-cases/export',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
                'Accept-Language': acceptLanguage,
            },
            query: {
                'tscId': tscId,
                'createdFrom': createdFrom,
                'createdTo': createdTo,
                'resolvedFrom': resolvedFrom,
                'resolvedTo': resolvedTo,
                'sortBy': sortBy,
                'sortDirection': sortDirection,
                'size': size,
                'start': start,
                'search.caseId': searchCaseId,
                'search.customerReference': searchCustomerReference,
                'search.organizationNumber': searchOrganizationNumber,
                'search.organizationName': searchOrganizationName,
                'search.subscriptionProductName': searchSubscriptionProductName,
                'search.subscriptionId': searchSubscriptionId,
                'search.subscriptionNumber': searchSubscriptionNumber,
                'search.serviceObjectName': searchServiceObjectName,
                'search.subscriptionAlias': searchSubscriptionAlias,
                'search.subscriptionStreet': searchSubscriptionStreet,
                'search.subscriptionCity': searchSubscriptionCity,
                'search.subscriptionCountry': searchSubscriptionCountry,
                'search.shortDescription': searchShortDescription,
                'search.description': searchDescription,
                'search.closeNotes': searchCloseNotes,
                'search.closeCode': searchCloseCode,
                'search.createdOn': searchCreatedOn,
                'search.resolvedOn': searchResolvedOn,
                'search.duration': searchDuration,
                'search.businessElapsedTime': searchBusinessElapsedTime,
                'search.closedOn': searchClosedOn,
                'search.guaranteedTime': searchGuaranteedTime,
                'search.reportingPerson': searchReportingPerson,
                'search.impact': searchImpact,
                'search.urgency': searchUrgency,
                'search.priority': searchPriority,
                'search.calculateSla': searchCalculateSla,
                'search.hasBreachedSla': searchHasBreachedSla,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

}
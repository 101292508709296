/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataResponseWrapperCustomerInfo } from '../models/DataResponseWrapperCustomerInfo';
import type { DataResponseWrapperLoginStatus } from '../models/DataResponseWrapperLoginStatus';
import type { DataResponseWrapperUserInfoResponse } from '../models/DataResponseWrapperUserInfoResponse';
import type { IdentifyRequest } from '../models/IdentifyRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserIdentifyControllerService {

    /**
     * Send person number in body or an empty request, when authenticated, to read data from auth headers. Non-authenticated customers will not be allowed the same information in response.
     * @param requestBody
     * @returns DataResponseWrapperCustomerInfo OK
     * @throws ApiError
     */
    public static lookup(
        requestBody?: IdentifyRequest,
    ): CancelablePromise<DataResponseWrapperCustomerInfo> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/lookup',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * @returns DataResponseWrapperUserInfoResponse OK
     * @throws ApiError
     */
    public static userInfo1(): CancelablePromise<DataResponseWrapperUserInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/userinfo',
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * @returns DataResponseWrapperLoginStatus OK
     * @throws ApiError
     */
    public static isLoggedIn1(): CancelablePromise<DataResponseWrapperLoginStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/logged-in',
            errors: {
                410: `Gone`,
            },
        });
    }

}
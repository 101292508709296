/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResponseEntity } from '../models/ResponseEntity';
import type { SetActiveServiceWebsOnOrganizationsRequestDTO } from '../models/SetActiveServiceWebsOnOrganizationsRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CsrServiceWebControllerService {

    /**
     * Set which service webs are active per organization
     * #token.hasAccess('TA_EDIT_CUSTOMERS')
     * @param groupId Customer Group ID
     * @param request Active service webs per organization tscid
     * @returns ResponseEntity OK
     * @returns any Created
     * @throws ApiError
     */
    public static setActiveServiceWebsOnOrganizationsUsingPut(
        groupId?: number,
        request?: SetActiveServiceWebsOnOrganizationsRequestDTO,
    ): CancelablePromise<ResponseEntity | any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/corp-sra-service/public/csr/customergroups/{groupId}/servicewebs',
            path: {
                'groupId': groupId,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateFederatedUserResponse } from '../models/CreateFederatedUserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiGardenControllerService {

    /**
     * createFederatedUser
     * permitAll()
     * @param customerGroupId Customer Group ID
     * @param userId User ID
     * @returns CreateFederatedUserResponse OK
     * @returns any Created
     * @throws ApiError
     */
    public static createFederatedUserUsingPost(
        customerGroupId: string,
        userId: string,
    ): CancelablePromise<CreateFederatedUserResponse | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-sra-service/public/v1/apigarden/createfederateduser',
            query: {
                'customerGroupId': customerGroupId,
                'userId': userId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderM365RequestBody } from '../models/OrderM365RequestBody';
import type { OrderM365ResponseBody } from '../models/OrderM365ResponseBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrderM365ControllerService {

    /**
     * Order Microsoft 365
     * @param body body
     * @param scopeId scopeId
     * @returns OrderM365ResponseBody Successful request
     * @throws ApiError
     */
    public static orderM365UsingPost(
        body: OrderM365RequestBody,
        scopeId: string,
    ): CancelablePromise<OrderM365ResponseBody> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-order-m365-service/{scopeId}/order',
            path: {
                'scopeId': scopeId,
            },
            body: body,
        });
    }

}
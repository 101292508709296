/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CacheSuccessResponse } from '../models/CacheSuccessResponse';
import type { SubscriptionProductDto } from '../models/SubscriptionProductDto';
import type { SubscriptionProductQuery } from '../models/SubscriptionProductQuery';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriptionProductControllerService {

    /**
     * Refreshes ALL IMEI SUBSCRIBSTION PRODUCTS cache.
     * @returns CacheSuccessResponse Successful operation
     * @throws ApiError
     */
    public static refreshImeiSubscriptionProducts(): CancelablePromise<CacheSuccessResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/dcse-pe-offering-catalog/imei-subscription-products/refresh',
            errors: {
                400: `Bad Request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Returns all active product subscription values (PIE table).
     * @returns SubscriptionProductDto Successful operation
     * @throws ApiError
     */
    public static getImeiSubscriptionProducts1(): CancelablePromise<SubscriptionProductDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dcse-pe-offering-catalog/get-all-imei-subscription-products',
            errors: {
                400: `Bad Request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Returns active product subscription values (PIE table) by request body.
     * @param requestBody
     * @returns SubscriptionProductDto Successful operation
     * @throws ApiError
     */
    public static getImeiSubscriptionProducts(
        requestBody: Array<SubscriptionProductQuery>,
    ): CancelablePromise<SubscriptionProductDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dcse-pe-offering-catalog/get-all-imei-subscription-products',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TechnicianResponseBody } from '../models/TechnicianResponseBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TechnicianControllerService {

    /**
     * @param tscId
     * @param xAuthScopeId
     * @returns TechnicianResponseBody OK
     * @throws ApiError
     */
    public static getTechnician(
        tscId: string,
        xAuthScopeId: any,
    ): CancelablePromise<TechnicianResponseBody> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/organizations/{tscId}/technician',
            path: {
                'tscId': tscId,
            },
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
        });
    }

}
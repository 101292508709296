/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImpactsDataResponseWrapper } from '../models/ImpactsDataResponseWrapper';
import type { ImpactsRequest } from '../models/ImpactsRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class V3Service {

    /**
     * @param requestBody
     * @returns ImpactsDataResponseWrapper Operation went as expected.
     * @throws ApiError
     */
    public static getImpacts(
        requestBody: ImpactsRequest,
    ): CancelablePromise<ImpactsDataResponseWrapper> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/impacts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request.`,
                410: `Gone`,
                500: `Server error.`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetCustomerPermissionsSuccessResponse } from '../models/GetCustomerPermissionsSuccessResponse';
import type { SetCustomerPermissionsRequest } from '../models/SetCustomerPermissionsRequest';
import type { SetCustomerPermissionsSuccessResponse } from '../models/SetCustomerPermissionsSuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class V2Service {

    /**
     * Fetches customer permissions (consent) for the logged in customer (using CIAM based Auth).
     * @returns GetCustomerPermissionsSuccessResponse OK
     * @throws ApiError
     */
    public static getCustomerPermissions(): CancelablePromise<GetCustomerPermissionsSuccessResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/customer-permissions',
        });
    }

    /**
     * Sets customer permissions (consent) for the logged in customer (using CIAM based Auth).
     * @param requestBody
     * @returns SetCustomerPermissionsSuccessResponse OK
     * @throws ApiError
     */
    public static setCustomerPermissions(
        requestBody: SetCustomerPermissionsRequest,
    ): CancelablePromise<SetCustomerPermissionsSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/customer-permissions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataResponseWrapperCustomerInfo } from '../models/DataResponseWrapperCustomerInfo';
import type { DataResponseWrapperLoginStatusV2 } from '../models/DataResponseWrapperLoginStatusV2';
import type { DataResponseWrapperUserInfoResponseV2 } from '../models/DataResponseWrapperUserInfoResponseV2';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserIdentifyV2ControllerService {

    /**
     * @returns DataResponseWrapperUserInfoResponseV2 OK
     * @throws ApiError
     */
    public static userInfo(): CancelablePromise<DataResponseWrapperUserInfoResponseV2> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/userinfo',
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Send empty request, when authenticated, to read data from auth headers. Non-authenticated customers will get a success response with loginStatus: { loggedIn: false }
     * @returns DataResponseWrapperCustomerInfo OK
     * @throws ApiError
     */
    public static lookup1(): CancelablePromise<DataResponseWrapperCustomerInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/lookup',
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * @returns DataResponseWrapperLoginStatusV2 OK
     * @throws ApiError
     */
    public static isLoggedIn(): CancelablePromise<DataResponseWrapperLoginStatusV2> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/logged-in',
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * @returns DataResponseWrapperUserInfoResponseV2 OK
     * @throws ApiError
     */
    public static internalUserInfo(): CancelablePromise<DataResponseWrapperUserInfoResponseV2> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/internal/userinfo',
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Send person number in body or an empty request, when authenticated, to read data from auth headers. Non-authenticated customers will not be allowed the same information in response.
     * @returns DataResponseWrapperCustomerInfo OK
     * @throws ApiError
     */
    public static internalLookup(): CancelablePromise<DataResponseWrapperCustomerInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/internal/lookup',
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * @returns DataResponseWrapperLoginStatusV2 OK
     * @throws ApiError
     */
    public static isInternalLoggedIn(): CancelablePromise<DataResponseWrapperLoginStatusV2> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/internal/logged-in',
            errors: {
                410: `Gone`,
            },
        });
    }

}
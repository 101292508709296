/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CacheSuccessResponse } from '../models/CacheSuccessResponse';
import type { CampaignSuccessResponse } from '../models/CampaignSuccessResponse';
import type { OfferingAvailableCampaignsRequest } from '../models/OfferingAvailableCampaignsRequest';
import type { OfferingAvailableCampaignsSuccessResponse } from '../models/OfferingAvailableCampaignsSuccessResponse';
import type { OfferingForMartechRequest } from '../models/OfferingForMartechRequest';
import type { OfferingListForMartechSuccessResponse } from '../models/OfferingListForMartechSuccessResponse';
import type { OfferingListRequest } from '../models/OfferingListRequest';
import type { OfferingListSuccessResponse } from '../models/OfferingListSuccessResponse';
import type { OfferingRequest } from '../models/OfferingRequest';
import type { OfferingsForMartechSuccessResponse } from '../models/OfferingsForMartechSuccessResponse';
import type { OfferingsRequest } from '../models/OfferingsRequest';
import type { OfferingsSuccessResponse } from '../models/OfferingsSuccessResponse';
import type { OfferingSuccessResponse } from '../models/OfferingSuccessResponse';
import type { PersonalizedOfferingListRequest } from '../models/PersonalizedOfferingListRequest';
import type { ProductOffering } from '../models/ProductOffering';
import type { RecommendedHardwareRequest } from '../models/RecommendedHardwareRequest';
import type { RecommendedProductVariant } from '../models/RecommendedProductVariant';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalServiceForReadingRawOfferingDataFromProductEditorService {

    /**
     * @param offeringId
     * @returns CacheSuccessResponse OK
     * @throws ApiError
     */
    public static refreshSingleOffering(
        offeringId: number,
    ): CancelablePromise<CacheSuccessResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/dcse-pe-offering-catalog/offerings/{offeringId}/refresh',
            path: {
                'offeringId': offeringId,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Refreshes the OFFERING cache by ids.
     * @param requestBody
     * @returns CacheSuccessResponse Successful operation
     * @throws ApiError
     */
    public static refreshOfferings(
        requestBody: Array<number>,
    ): CancelablePromise<CacheSuccessResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/dcse-pe-offering-catalog/offerings/refresh',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Refreshes ALL OFFERINGS details cache. WARNING: this is a heavy operation, use this with care.
     * @returns CacheSuccessResponse Successful operation
     * @throws ApiError
     */
    public static refreshOfferingsDetails(): CancelablePromise<CacheSuccessResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/dcse-pe-offering-catalog/offerings-details/refresh',
            errors: {
                400: `Bad Request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Refresh personalized offerings
     * @returns CacheSuccessResponse Successful operation
     * @throws ApiError
     */
    public static refreshPersonalizedOfferings(): CancelablePromise<CacheSuccessResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/dcse-pe-offering-catalog/offering/list-personalized/refresh',
            errors: {
                400: `Bad Request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Refresh offerings list for MarTech
     * @returns CacheSuccessResponse Successful operation
     * @throws ApiError
     */
    public static refreshOfferingsForMartech(): CancelablePromise<CacheSuccessResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/dcse-pe-offering-catalog/offering/list-martech/refresh',
            errors: {
                400: `Bad Request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Read raw offering data. Contains metadata, packages and products. Performs package filtering if map arguments are provided.
     * @param requestBody
     * @returns OfferingsSuccessResponse Successful operation
     * @throws ApiError
     */
    public static getOfferings(
        requestBody: OfferingsRequest,
    ): CancelablePromise<OfferingsSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dcse-pe-offering-catalog/offerings/v1',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Fetch recommended B2C hardware accessories for given products and offering type
     * @param requestBody
     * @returns RecommendedProductVariant Successful operation - can return an empty list of recommended hardware
     * @throws ApiError
     */
    public static getRecommendedHardware(
        requestBody: RecommendedHardwareRequest,
    ): CancelablePromise<Array<RecommendedProductVariant>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dcse-pe-offering-catalog/offerings/recommended-hardware',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Read raw offering data. Contains metadata, packages, filters on a package level and products.
     * @param requestBody
     * @returns OfferingsForMartechSuccessResponse Successful operation
     * @throws ApiError
     */
    public static getOfferingsForMartech(
        requestBody: OfferingForMartechRequest,
    ): CancelablePromise<OfferingsForMartechSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dcse-pe-offering-catalog/offerings/Martech/v1',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Read raw offering data. Contains metadata, packages and products. Performs package filtering if map arguments are provided.
     * @param requestBody
     * @returns OfferingSuccessResponse Successful operation
     * @throws ApiError
     */
    public static getOffering(
        requestBody: OfferingRequest,
    ): CancelablePromise<OfferingSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dcse-pe-offering-catalog/offering/v1',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Fetch offerings that match the given map arguments.
     * @param requestBody
     * @returns OfferingListSuccessResponse Successful operation - can return an empty list of offerings
     * @throws ApiError
     */
    public static listOfferingsV2(
        requestBody: OfferingListRequest,
    ): CancelablePromise<OfferingListSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dcse-pe-offering-catalog/offering/list/v2',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * @deprecated
     * DEPRECATED: Fetch offerings that match the given map arguments.
     * @param requestBody
     * @returns OfferingListSuccessResponse Successful operation
     * @throws ApiError
     */
    public static listOfferings(
        requestBody: OfferingListRequest,
    ): CancelablePromise<OfferingListSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dcse-pe-offering-catalog/offering/list/v1',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Fetch personalized offerings.
     * @param requestBody
     * @returns ProductOffering Successful operation
     * @throws ApiError
     */
    public static listOfferingsPersonalized(
        requestBody: PersonalizedOfferingListRequest,
    ): CancelablePromise<Array<ProductOffering>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dcse-pe-offering-catalog/offering/list-personalized',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Read raw offering data. Contains active campaign filters. Performs package filtering if map arguments are provided.
     * @param requestBody
     * @returns OfferingAvailableCampaignsSuccessResponse Successful operation
     * @throws ApiError
     */
    public static getOfferingAvailableCampaigns(
        requestBody: OfferingAvailableCampaignsRequest,
    ): CancelablePromise<OfferingAvailableCampaignsSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dcse-pe-offering-catalog/offering/availablecampaigns/v1',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Read raw campaign offering data. Contains metadata, packages and products. Performs package filtering if map arguments are provided.
     * @param requestBody
     * @returns CampaignSuccessResponse Successful operation
     * @throws ApiError
     */
    public static getCampaignOffering(
        requestBody: OfferingRequest,
    ): CancelablePromise<CampaignSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dcse-pe-offering-catalog/campaign/v1',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Fetch a campaign offering with given id and performs package filtering with optional map arguments.
     * @param requestBody
     * @returns OfferingListSuccessResponse Successful operation - can return an empty list of offerings
     * @throws ApiError
     */
    public static listCampaigns(
        requestBody: OfferingListRequest,
    ): CancelablePromise<OfferingListSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dcse-pe-offering-catalog/campaign/list/v1',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Refreshes the OFFERING_LIST cache for the provided salesChannel.
     * @param salesChannel
     * @returns CacheSuccessResponse Successful operation
     * @throws ApiError
     */
    public static refreshOfferingList(
        salesChannel: 'CAESAR' | 'TELIASE' | 'RETAILER' | 'OURTELIA' | 'HALEBOP',
    ): CancelablePromise<CacheSuccessResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dcse-pe-offering-catalog/offerings/{salesChannel}/refresh',
            path: {
                'salesChannel': salesChannel,
            },
            errors: {
                400: `Bad Request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Fetch offerings for Martech.
     * @returns OfferingListForMartechSuccessResponse Successful operation
     * @throws ApiError
     */
    public static listOfferingsForMartech(): CancelablePromise<Array<OfferingListForMartechSuccessResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dcse-pe-offering-catalog/offering/list/Martech/v1',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Refreshes the CAMPAIGN_LIST cache.
     * @returns CacheSuccessResponse Successful operation
     * @throws ApiError
     */
    public static refreshCampaignList(): CancelablePromise<CacheSuccessResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dcse-pe-offering-catalog/campaigns/refresh',
            errors: {
                400: `Bad Request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

}
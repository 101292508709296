/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrderResponse } from '../models/CreateOrderResponse';
import type { SalesStoreReservationRequest } from '../models/SalesStoreReservationRequest';
import type { SearchStoreResponse } from '../models/SearchStoreResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * @param productCode
     * @param address
     * @returns SearchStoreResponse successful operation
     * @throws ApiError
     */
    public static searchAvailableStoresByAddress(
        productCode: string,
        address: string,
    ): CancelablePromise<SearchStoreResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/productCode/{productCode}/address/{address}',
            path: {
                'productCode': productCode,
                'address': address,
            },
        });
    }

    /**
     * @param productCode
     * @param lon
     * @param lat
     * @returns SearchStoreResponse successful operation
     * @throws ApiError
     */
    public static searchAvailableStoresByLocation(
        productCode: string,
        lon: number,
        lat: number,
    ): CancelablePromise<SearchStoreResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/productCode/{productCode}/lon/{lon}/lat/{lat}',
            path: {
                'productCode': productCode,
                'lon': lon,
                'lat': lat,
            },
        });
    }

    /**
     * @param stockCode
     * @param body
     * @returns CreateOrderResponse successful operation
     * @throws ApiError
     */
    public static createOrder(
        stockCode: string,
        body?: SalesStoreReservationRequest,
    ): CancelablePromise<CreateOrderResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/store/{stockCode}/reservation',
            path: {
                'stockCode': stockCode,
            },
            body: body,
        });
    }

}
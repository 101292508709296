/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSuperUserInviteDTO } from '../models/CreateSuperUserInviteDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CsrInviteControllerService {

    /**
     * Create and send an invite that gives Super User access
     * #token.hasAccess('TA_EDIT_CUSTOMERS')
     * @param createSuperUserInviteDto createSuperUserInviteDTO
     * @param groupId Customer Group ID
     * @returns any OK
     * @throws ApiError
     */
    public static createSuperUserInviteUsingPost(
        createSuperUserInviteDto: CreateSuperUserInviteDTO,
        groupId?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-sra-service/public/csr/customergroups/{groupId}/invites/superusers',
            path: {
                'groupId': groupId,
            },
            body: createSuperUserInviteDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Cancel possible Super User invite
     * #token.hasAccess('TA_EDIT_CUSTOMERS')
     * @param groupId Customer Group ID
     * @returns any OK
     * @throws ApiError
     */
    public static cancelSuperUserInviteUsingDelete(
        groupId?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/corp-sra-service/public/csr/customergroups/{groupId}/invites/superusers',
            path: {
                'groupId': groupId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetCustomerGroupCsrResponseDTO } from '../models/GetCustomerGroupCsrResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CsrStructureControllerService {

    /**
     * Get a customer group with it's organizations
     * #token.hasAccess('TA_VIEW_CUSTOMERS')
     * @param groupId Customer Group ID
     * @returns GetCustomerGroupCsrResponseDTO OK
     * @throws ApiError
     */
    public static getCustomerGroupCsrUsingGet(
        groupId?: number,
    ): CancelablePromise<GetCustomerGroupCsrResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/csr/customergroups/{groupId}',
            path: {
                'groupId': groupId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
export * from "./generated/services";

declare global {
  interface Window {
    answer: string;
  }
}

// single-spa exports
export async function bootstrap(): Promise<void> {
  return Promise.resolve();
}

export async function mount(): Promise<void> {
  return Promise.resolve();
}

export async function unmount(): Promise<void> {
  return Promise.resolve();
}

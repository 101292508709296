/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeliveryDatesRequest } from '../models/DeliveryDatesRequest';
import type { DeliveryDatesSuccessResponse } from '../models/DeliveryDatesSuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class V2Service {

    /**
     * Fetch available delivery dates. Uses CIAM based Auth.
     * @param requestBody
     * @returns DeliveryDatesSuccessResponse Successful operation
     * @throws ApiError
     */
    public static v2(
        requestBody: DeliveryDatesRequest,
    ): CancelablePromise<DeliveryDatesSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                500: `Internal server error`,
                503: `Service temporarily unavailable`,
            },
        });
    }

    /**
     * Fetch available delivery dates. Internal endpoint.
     * @param requestBody
     * @returns DeliveryDatesSuccessResponse Successful operation
     * @throws ApiError
     */
    public static internalV2(
        requestBody: DeliveryDatesRequest,
    ): CancelablePromise<DeliveryDatesSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/internal',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                500: `Internal server error`,
                503: `Service temporarily unavailable`,
            },
        });
    }

}
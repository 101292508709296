/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CorpCaseExportService {

    /**
     * Export cases
     * @returns string OK
     * @throws ApiError
     */
    public static exportCasess({
        xAuthScopeId,
        acceptLanguage,
        sortBy,
        relatedCaseIds,
        sortDirection,
        size,
        start,
        organizationTscIds,
        statuses,
        categories,
        productCodes,
        excludeCloseCodes,
        createdFrom,
        createdTo,
        resolvedFrom,
        resolvedTo,
        textSearch,
        searchCaseId,
        searchShortDescription,
        searchUpdatedOn,
        searchCreatedOn,
        searchSubscriptionNumber,
        searchReportingPerson,
        searchStatus,
        searchCategory,
        searchSubscriptionAlias,
        searchCountry,
        searchCity,
        searchStreet,
        searchCustomerReference,
        c2BUid,
        accept,
    }: {
        xAuthScopeId: any,
        acceptLanguage?: any,
        /** Attribute to sort cases by. **/
        sortBy?: 'CASE_ID' | 'SHORT_DESCRIPTION' | 'STATUS' | 'CATEGORY' | 'UPDATED_ON' | 'ASSET_ALIAS' | 'CREATED_ON' | 'SUBSCRIPTION_ID' | 'SUBSCRIPTION_NUMBER' | 'REPORTING_PERSON' | 'CUSTOMER_REFERENCE' | 'COUNTRY' | 'CITY' | 'STREET' | 'ORGANIZATION_NUMBER' | 'ORGANIZATION_NAME' | 'SUBSCRIPTION_PRODUCT_NAME' | 'SERVICE_OBJECT_NAME' | 'DESCRIPTION' | 'CLOSE_NOTES' | 'CLOSE_CODE' | 'RESOLVED_ON' | 'DURATION' | 'BUSINESS_ELAPSED_TIME' | 'CLOSED_ON' | 'IMPACT' | 'URGENCY' | 'PRIORITY' | 'GUARANTEED_TIME' | 'CALCULATE_SLA' | 'HAS_BREACHED_SLA' | 'SUBSCRIPTION_STREET' | 'SUBSCRIPTION_CITY' | 'SUBSCRIPTION_COUNTRY' | 'SUBSCRIPTION_ALIAS',
        relatedCaseIds?: Array<string>,
        sortDirection?: 'ASC' | 'DESC',
        size?: number,
        start?: number,
        organizationTscIds?: Array<string>,
        statuses?: Array<'NEW' | 'OPEN' | 'AWAITING_INFO' | 'RESOLVED' | 'CLOSED' | 'CANCELLED' | 'UNRECOGNIZED'>,
        categories?: Array<'GDPR' | 'INCIDENT' | 'SERVICE_REQUEST' | 'INVOICE' | 'PERSONAL_TECHNICIAN' | 'USER_SUPPORT' | 'FEEDBACK' | 'DELIVERY' | 'ORDERING' | 'LEDGER' | 'UNRECOGNIZED'>,
        productCodes?: Array<string>,
        excludeCloseCodes?: Array<string>,
        /** Date formats: YYYY-MM-DD **/
        createdFrom?: string,
        /** Date formats: YYYY-MM-DD **/
        createdTo?: string,
        /** Date formats: YYYY-MM-DD **/
        resolvedFrom?: string,
        /** Date formats: YYYY-MM-DD **/
        resolvedTo?: string,
        /** String for searching cases by keyword or part of a keyword. The search is performed on the following attributes: caseId, shortDescription and subscriptionId. **/
        textSearch?: string,
        searchCaseId?: string,
        searchShortDescription?: string,
        searchUpdatedOn?: string,
        searchCreatedOn?: string,
        searchSubscriptionNumber?: string,
        searchReportingPerson?: string,
        searchStatus?: string,
        searchCategory?: 'GDPR' | 'INCIDENT' | 'SERVICE_REQUEST' | 'INVOICE' | 'PERSONAL_TECHNICIAN' | 'USER_SUPPORT' | 'FEEDBACK' | 'DELIVERY' | 'ORDERING' | 'LEDGER' | 'UNRECOGNIZED',
        searchSubscriptionAlias?: string,
        searchCountry?: string,
        searchCity?: string,
        searchStreet?: string,
        searchCustomerReference?: string,
        c2BUid?: string,
        accept?: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/cases/export',
            headers: {
                'Accept-Language': acceptLanguage,
                'accept': accept,
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'sortBy': sortBy,
                'relatedCaseIds': relatedCaseIds,
                'sortDirection': sortDirection,
                'size': size,
                'start': start,
                'organizationTscIds': organizationTscIds,
                'statuses': statuses,
                'categories': categories,
                'productCodes': productCodes,
                'excludeCloseCodes': excludeCloseCodes,
                'createdFrom': createdFrom,
                'createdTo': createdTo,
                'resolvedFrom': resolvedFrom,
                'resolvedTo': resolvedTo,
                'textSearch': textSearch,
                'search.caseId': searchCaseId,
                'search.shortDescription': searchShortDescription,
                'search.updatedOn': searchUpdatedOn,
                'search.createdOn': searchCreatedOn,
                'search.subscriptionNumber': searchSubscriptionNumber,
                'search.reportingPerson': searchReportingPerson,
                'search.status': searchStatus,
                'search.category': searchCategory,
                'search.subscriptionAlias': searchSubscriptionAlias,
                'search.country': searchCountry,
                'search.city': searchCity,
                'search.street': searchStreet,
                'search.customerReference': searchCustomerReference,
                'c2bUid': c2BUid,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CacheSuccessResponse } from '../models/CacheSuccessResponse';
import type { ImeiDiscountQuery } from '../models/ImeiDiscountQuery';
import type { ImeiDiscountViewDto } from '../models/ImeiDiscountViewDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ServiceForReadingImeiDiscountDataFromProductEditorService {

    /**
     * Refreshes ALL IMEI DISCOUNTS cache.
     * @returns CacheSuccessResponse Successful operation
     * @throws ApiError
     */
    public static refreshImeiDiscounts(): CancelablePromise<CacheSuccessResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/dcse-pe-offering-catalog/imei-discounts/refresh',
            errors: {
                400: `Bad Request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Fetch all imei discounts (applicable to hardware when combined with a subscription), active at a provided preview-date (or current time). Discounts bound to offerings are not included in the response. Example url with preview-date: /get-all-imei-discounts?preview-date=2023-08-17T08:15:00
     * @returns ImeiDiscountViewDto Successful operation - can return an empty list of discounts
     * @throws ApiError
     */
    public static getAllDiscounts(): CancelablePromise<Array<ImeiDiscountViewDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dcse-pe-offering-catalog/get-all-imei-discounts',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

    /**
     * Fetch imei discounts with request parameters (applicable to hardware when combined with a subscription), active at a provided preview-date (or current time). Discounts bound to offerings are not included in the response. Example url with preview-date: /get-all-imei-discounts?preview-date=2023-08-17T08:15:00
     * @param requestBody
     * @returns ImeiDiscountViewDto Successful operation - can return an empty list of discounts
     * @throws ApiError
     */
    public static getDiscounts(
        requestBody: Array<ImeiDiscountQuery>,
    ): CancelablePromise<Array<ImeiDiscountViewDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dcse-pe-offering-catalog/get-all-imei-discounts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                410: `Gone`,
                500: `Integration error or other internal error`,
            },
        });
    }

}
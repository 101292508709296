/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SegmentResponseDto } from '../models/SegmentResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PublicService {

    /**
     * Get segment on a CustomerGroup by ScopeId
     * @param scopeId Customer identification number
     * @returns SegmentResponseDto Success
     * @throws ApiError
     */
    public static getSegment1(
        scopeId: string,
    ): CancelablePromise<SegmentResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/segment',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreditCardInput } from '../models/CreditCardInput';
import type { PayexRequest } from '../models/PayexRequest';
import type { ProcessPaymentRequest } from '../models/ProcessPaymentRequest';
import type { RegisterPaymentRequest } from '../models/RegisterPaymentRequest';
import type { ResponseWrapperBoolean } from '../models/ResponseWrapperBoolean';
import type { ResponseWrapperObject } from '../models/ResponseWrapperObject';
import type { ResponseWrapperPreAuthPresentation } from '../models/ResponseWrapperPreAuthPresentation';
import type { ResponseWrapperPresentationOrder } from '../models/ResponseWrapperPresentationOrder';
import type { ResponseWrapperStatusResponse } from '../models/ResponseWrapperStatusResponse';
import type { UpdatePaymentRequest } from '../models/UpdatePaymentRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PublicV2Service {

    /**
     * Preauthorize customer towards Telia Finance
     * @param requestBody
     * @returns ResponseWrapperPreAuthPresentation OK
     * @throws ApiError
     */
    public static preAuth(
        requestBody: PayexRequest,
    ): CancelablePromise<ResponseWrapperPreAuthPresentation> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/telia-finance/preauth',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Writes an order to the payment log for future handling
     * @param requestBody
     * @returns ResponseWrapperBoolean OK
     * @throws ApiError
     */
    public static register(
        requestBody: RegisterPaymentRequest,
    ): CancelablePromise<ResponseWrapperBoolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Get status of a payment
     * @param orderId
     * @returns ResponseWrapperPresentationOrder OK
     * @throws ApiError
     */
    public static getPaymentPublic1(
        orderId: number,
    ): CancelablePromise<ResponseWrapperPresentationOrder> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/order/{orderId}',
            path: {
                'orderId': orderId,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Process a payment order
     * @param orderId
     * @param requestBody
     * @returns ResponseWrapperObject OK
     * @throws ApiError
     */
    public static process1(
        orderId: number,
        requestBody?: ProcessPaymentRequest,
    ): CancelablePromise<ResponseWrapperObject> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/order/{orderId}',
            path: {
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Update existing order and create a new payment
     * @param orderId
     * @param requestBody
     * @returns ResponseWrapperBoolean OK
     * @throws ApiError
     */
    public static updatePaymentPublic(
        orderId: number,
        requestBody: UpdatePaymentRequest,
    ): CancelablePromise<ResponseWrapperBoolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/order/{orderId}/update-payment',
            path: {
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Get status of credit card order and handle payment status. Used for FE polling.
     * @param orderId
     * @returns ResponseWrapperStatusResponse OK
     * @throws ApiError
     */
    public static pollPrivateCreditCard(
        orderId: number,
    ): CancelablePromise<ResponseWrapperStatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/credit-card/{orderId}',
            path: {
                'orderId': orderId,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Post 3DS credentials to authenticate credit card payment
     * @param orderId
     * @param requestBody
     * @returns ResponseWrapperStatusResponse OK
     * @throws ApiError
     */
    public static inputPrivateCreditCard(
        orderId: number,
        requestBody: CreditCardInput,
    ): CancelablePromise<ResponseWrapperStatusResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/credit-card/{orderId}',
            path: {
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Get status of swish order and handle payment status. Used for FE polling.
     * @param orderId
     * @returns ResponseWrapperStatusResponse OK
     * @throws ApiError
     */
    public static pollPrivateSwish(
        orderId: number,
    ): CancelablePromise<ResponseWrapperStatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/swish/{orderId}',
            path: {
                'orderId': orderId,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DecryptRequest } from '../models/DecryptRequest';
import type { MobilePhoneValuationRequest } from '../models/MobilePhoneValuationRequest';
import type { MobilePhoneValuationSuccessResponse } from '../models/MobilePhoneValuationSuccessResponse';
import type { MobilePhoneValuationV2Response } from '../models/MobilePhoneValuationV2Response';
import type { SearchValuationSuccessResponse } from '../models/SearchValuationSuccessResponse';
import type { SingleItemSuccessResponse } from '../models/SingleItemSuccessResponse';
import type { ValuationRequest } from '../models/ValuationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RestEndpointsForMobileValuationService {

    /**
     * Lookup valuation of a mobile device
     * @param body
     * @returns MobilePhoneValuationV2Response successful operation
     * @throws ApiError
     */
    public static post(
        body?: MobilePhoneValuationRequest,
    ): CancelablePromise<MobilePhoneValuationV2Response> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2',
            body: body,
        });
    }

    /**
     * Retrieves decrypted mobile valuation data
     * @param body
     * @returns MobilePhoneValuationV2Response successful operation
     * @throws ApiError
     */
    public static decrypt(
        body?: DecryptRequest,
    ): CancelablePromise<MobilePhoneValuationV2Response> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/decrypt',
            body: body,
        });
    }

    /**
     * Lookup valuation of a mobile device based on device ID
     * @param id
     * @param body
     * @returns SearchValuationSuccessResponse successful operation
     * @throws ApiError
     */
    public static idValuation(
        id: string,
        body?: ValuationRequest,
    ): CancelablePromise<SearchValuationSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/devices/{id}',
            path: {
                'id': id,
            },
            body: body,
        });
    }

    /**
     * Lookup valuation of a mobile device
     * @param body
     * @returns MobilePhoneValuationSuccessResponse successful operation
     * @throws ApiError
     */
    public static post1(
        body?: MobilePhoneValuationRequest,
    ): CancelablePromise<MobilePhoneValuationSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: 'v1',
            body: body,
        });
    }

    /**
     * Retrieves cached valuation for provided token
     * @param token
     * @returns SingleItemSuccessResponse successful operation
     * @throws ApiError
     */
    public static getItem(
        token: string,
    ): CancelablePromise<SingleItemSuccessResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: 'v1/item/{token}',
            path: {
                'token': token,
            },
        });
    }

}
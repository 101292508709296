/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressNetworkDetailsGetSuccessResponse } from '../models/AddressNetworkDetailsGetSuccessResponse';
import type { AddressNetworkDetailsPostSuccessResponse } from '../models/AddressNetworkDetailsPostSuccessResponse';
import type { AddressNetworkDetailsRequest } from '../models/AddressNetworkDetailsRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AddressNetworkDetailsService {

    /**
     * List network details for an address
     * @param requestBody
     * @returns AddressNetworkDetailsPostSuccessResponse Everything went as expected. Response may or may not contain addresses.
     * @throws ApiError
     */
    public static addressNetworkDetailsV1(
        requestBody: AddressNetworkDetailsRequest,
    ): CancelablePromise<AddressNetworkDetailsPostSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Request is missing a required field, or has faulty formatting.`,
                500: `Unexpected error, most likely due to the Precheck integration.`,
            },
        });
    }

    /**
     * Get network details for a specific point ID
     * @param pointId Point ID for the chosen address. Minimum value is 0 and maximum length is 9 digits.
     * @returns AddressNetworkDetailsGetSuccessResponse Everything went as expected.
     * @throws ApiError
     */
    public static addressNetworkDetailByPointIdV1(
        pointId: number,
    ): CancelablePromise<AddressNetworkDetailsGetSuccessResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/{pointId}',
            path: {
                'pointId': pointId,
            },
            errors: {
                400: `Request has an invalid point ID.`,
                500: `Unexpected error, most likely due to the Precheck integration.`,
            },
        });
    }

}
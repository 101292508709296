/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeliveryOptionRequest } from '../models/DeliveryOptionRequest';
import type { DeliveryTimeRequest } from '../models/DeliveryTimeRequest';
import type { ResponseWrapperDeliveryOptionsResponse } from '../models/ResponseWrapperDeliveryOptionsResponse';
import type { ResponseWrapperDeliveryTimeResponse } from '../models/ResponseWrapperDeliveryTimeResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DeliveryOptionsControllerService {

    /**
     * @param requestBody
     * @returns ResponseWrapperDeliveryOptionsResponse OK
     * @throws ApiError
     */
    public static getDeliveryOptions(
        requestBody: DeliveryOptionRequest,
    ): CancelablePromise<ResponseWrapperDeliveryOptionsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/getDeliveryOptions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ResponseWrapperDeliveryTimeResponse OK
     * @throws ApiError
     */
    public static getStoreDeliveryTime(
        requestBody: DeliveryTimeRequest,
    ): CancelablePromise<ResponseWrapperDeliveryTimeResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/deliveryTime',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptInviteDTO } from '../models/AcceptInviteDTO';
import type { AccessProfileIdDTO } from '../models/AccessProfileIdDTO';
import type { CreateInviteDTO } from '../models/CreateInviteDTO';
import type { CreateInviteResponseDTO } from '../models/CreateInviteResponseDTO';
import type { DomainIdVO } from '../models/DomainIdVO';
import type { GetInviteHeaderResponseDTO } from '../models/GetInviteHeaderResponseDTO';
import type { GetInvitesResponseDTO } from '../models/GetInvitesResponseDTO';
import type { InviteDTO } from '../models/InviteDTO';
import type { NonceAndSmsTokenDTO } from '../models/NonceAndSmsTokenDTO';
import type { NonceDTO } from '../models/NonceDTO';
import type { ValidationCodeDTO } from '../models/ValidationCodeDTO';
import type { ValidationResponseDTO } from '../models/ValidationResponseDTO';
import type { VersionDTO } from '../models/VersionDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MyBusinessInviteControllerService {

    /**
     * Get unaccepted invites
     * #token.hasAccess('MANAGE_USER')
     * @param groupId Customer Group ID
     * @returns GetInvitesResponseDTO OK
     * @throws ApiError
     */
    public static getInvitesOnGroupUsingGet(
        groupId: number,
    ): CancelablePromise<GetInvitesResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/invites',
            path: {
                'groupId': groupId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Create an invite
     * #token.hasAccess('MANAGE_USER')
     * @param createInviteDto createInviteDTO
     * @param groupId Customer Group ID
     * @returns CreateInviteResponseDTO invite id and version
     * @returns any Created
     * @throws ApiError
     */
    public static createInviteUsingPost(
        createInviteDto: CreateInviteDTO,
        groupId: number,
    ): CancelablePromise<CreateInviteResponseDTO | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/invites',
            path: {
                'groupId': groupId,
            },
            body: createInviteDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Updates the invite to status 'CANCELED'
     * #token.hasAccess('MANAGE_USER')
     * @param groupId groupId
     * @param inviteId Invite id
     * @returns InviteDTO Invite canceled
     * @throws ApiError
     */
    public static cancelInviteUsingDelete(
        groupId: number,
        inviteId: number,
    ): CancelablePromise<InviteDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/invites/{inviteId}',
            path: {
                'groupId': groupId,
                'inviteId': inviteId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * sendInvite
     * #token.hasAccess('MANAGE_USER')
     * @param groupId Customer Group ID
     * @param inviteId Invite ID
     * @param version version
     * @returns any Successfully sent invite
     * @throws ApiError
     */
    public static sendInviteUsingPost(
        groupId: number,
        inviteId: number,
        version: VersionDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/invites/{inviteId}/send',
            path: {
                'groupId': groupId,
                'inviteId': inviteId,
            },
            body: version,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Accept invite as a new user (account will be created)
     * permitAll()
     * @param acceptInviteDto acceptInviteDTO
     * @param inviteId Invite id
     * @returns DomainIdVO User account created and profile created
     * @returns any Created
     * @throws ApiError
     */
    public static acceptInviteAsNewUserUsingPost(
        acceptInviteDto: AcceptInviteDTO,
        inviteId: number,
    ): CancelablePromise<DomainIdVO | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-sra-service/public/mybusiness/invites/{inviteId}/accept',
            path: {
                'inviteId': inviteId,
            },
            body: acceptInviteDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Username exists or otherwise invalid state`,
            },
        });
    }

    /**
     * Accept invite as an existing user (connect the logged in tcwss user to the SRA group)
     * isAuthenticated()
     * @param inviteId Invite ID
     * @param validationCodeDto validationCodeDTO
     * @returns AccessProfileIdDTO Profile created on the group
     * @returns any Created
     * @throws ApiError
     */
    public static acceptInviteAsExistingUserUsingPost(
        inviteId: number,
        validationCodeDto: ValidationCodeDTO,
    ): CancelablePromise<AccessProfileIdDTO | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-sra-service/public/mybusiness/invites/{inviteId}/accept/existing-user',
            path: {
                'inviteId': inviteId,
            },
            body: validationCodeDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `User does already have a profile on the group`,
            },
        });
    }

    /**
     * getInviteHeader
     * permitAll()
     * @param inviteId Invite id
     * @param nonce
     * @returns GetInviteHeaderResponseDTO Invite header information
     * @throws ApiError
     */
    public static getInviteHeaderUsingGet(
        inviteId: number,
        nonce?: string,
    ): CancelablePromise<GetInviteHeaderResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/invites/{inviteId}/header',
            path: {
                'inviteId': inviteId,
            },
            query: {
                'nonce': nonce,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * requestSmsToken
     * permitAll()
     * @param inviteId Invite id
     * @param nonceDto nonceDTO
     * @returns any OK
     * @throws ApiError
     */
    public static requestSmsTokenUsingPost(
        inviteId: number,
        nonceDto: NonceDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-sra-service/public/mybusiness/invites/{inviteId}/smstoken',
            path: {
                'inviteId': inviteId,
            },
            body: nonceDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * validateSmsToken
     * permitAll()
     * @param input input
     * @param inviteId Invite id
     * @returns ValidationResponseDTO Invite details and validation code
     * @returns any Created
     * @throws ApiError
     */
    public static validateSmsTokenUsingPost(
        input: NonceAndSmsTokenDTO,
        inviteId: number,
    ): CancelablePromise<ValidationResponseDTO | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-sra-service/public/mybusiness/invites/{inviteId}/smstoken/validate',
            path: {
                'inviteId': inviteId,
            },
            body: input,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RecommendationsRequest } from '../models/RecommendationsRequest';
import type { RecommendationsResponse } from '../models/RecommendationsResponse';
import type { ValidateResponseWrapper } from '../models/ValidateResponseWrapper';
import type { ValidationRequest } from '../models/ValidationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class V2CiamAuthService {

    /**
     * @param requestBody
     * @returns ValidateResponseWrapper OK
     * @throws ApiError
     */
    public static validate(
        requestBody: ValidationRequest,
    ): CancelablePromise<ValidateResponseWrapper> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/validate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns RecommendationsResponse OK
     * @throws ApiError
     */
    public static getRelatedProducts(
        requestBody: RecommendationsRequest,
    ): CancelablePromise<RecommendationsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/recommendations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ValidateResponseWrapper OK
     * @throws ApiError
     */
    public static validateInternal(
        requestBody: ValidationRequest,
    ): CancelablePromise<ValidateResponseWrapper> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/internal/validate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
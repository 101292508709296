/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderInformationResponseOrderSelectionData } from '../models/OrderInformationResponseOrderSelectionData';
import type { OrderInformationResponseResponseData } from '../models/OrderInformationResponseResponseData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class V2Service {

    /**
     * @param orderNumber
     * @returns OrderInformationResponseOrderSelectionData OK
     * @throws ApiError
     */
    public static getOrderSelections(
        orderNumber: string,
    ): CancelablePromise<OrderInformationResponseOrderSelectionData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/orderSelections/{orderNumber}',
            path: {
                'orderNumber': orderNumber,
            },
        });
    }

    /**
     * @param orderNumber
     * @returns OrderInformationResponseResponseData OK
     * @throws ApiError
     */
    public static getOrderInformation(
        orderNumber: number,
    ): CancelablePromise<OrderInformationResponseResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/order/{orderNumber}',
            path: {
                'orderNumber': orderNumber,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LogRequest } from '../models/LogRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FrontendLoggerControllerService {

    /**
     * log
     * @param log log
     * @param id
     * @param localAddressAddressAddress
     * @param localAddressAddressAnyLocalAddress
     * @param localAddressAddressCanonicalHostName
     * @param localAddressAddressHostAddress
     * @param localAddressAddressHostName
     * @param localAddressAddressLinkLocalAddress
     * @param localAddressAddressLoopbackAddress
     * @param localAddressAddressMcGlobal
     * @param localAddressAddressMcLinkLocal
     * @param localAddressAddressMcNodeLocal
     * @param localAddressAddressMcOrgLocal
     * @param localAddressAddressMcSiteLocal
     * @param localAddressAddressMulticastAddress
     * @param localAddressAddressSiteLocalAddress
     * @param localAddressHostName
     * @param localAddressHostString
     * @param localAddressPort
     * @param localAddressUnresolved
     * @param remoteAddressAddressAddress
     * @param remoteAddressAddressAnyLocalAddress
     * @param remoteAddressAddressCanonicalHostName
     * @param remoteAddressAddressHostAddress
     * @param remoteAddressAddressHostName
     * @param remoteAddressAddressLinkLocalAddress
     * @param remoteAddressAddressLoopbackAddress
     * @param remoteAddressAddressMcGlobal
     * @param remoteAddressAddressMcLinkLocal
     * @param remoteAddressAddressMcNodeLocal
     * @param remoteAddressAddressMcOrgLocal
     * @param remoteAddressAddressMcSiteLocal
     * @param remoteAddressAddressMulticastAddress
     * @param remoteAddressAddressSiteLocalAddress
     * @param remoteAddressHostName
     * @param remoteAddressHostString
     * @param remoteAddressPort
     * @param remoteAddressUnresolved
     * @param sslInfoPeerCertificates0BasicConstraints
     * @param sslInfoPeerCertificates0Encoded
     * @param sslInfoPeerCertificates0ExtendedKeyUsage
     * @param sslInfoPeerCertificates0IssuerDnName
     * @param sslInfoPeerCertificates0IssuerUniqueId
     * @param sslInfoPeerCertificates0IssuerX500PrincipalEncoded
     * @param sslInfoPeerCertificates0IssuerX500PrincipalName
     * @param sslInfoPeerCertificates0KeyUsage
     * @param sslInfoPeerCertificates0NotAfter
     * @param sslInfoPeerCertificates0NotBefore
     * @param sslInfoPeerCertificates0SerialNumber
     * @param sslInfoPeerCertificates0SigAlgName
     * @param sslInfoPeerCertificates0SigAlgOid
     * @param sslInfoPeerCertificates0SigAlgParams
     * @param sslInfoPeerCertificates0Signature
     * @param sslInfoPeerCertificates0SubjectDnName
     * @param sslInfoPeerCertificates0SubjectUniqueId
     * @param sslInfoPeerCertificates0SubjectX500PrincipalEncoded
     * @param sslInfoPeerCertificates0SubjectX500PrincipalName
     * @param sslInfoPeerCertificates0TbsCertificate
     * @param sslInfoPeerCertificates0Type
     * @param sslInfoPeerCertificates0Version
     * @param sslInfoSessionId
     * @returns any Created
     * @throws ApiError
     */
    public static logUsingPost(
        log: LogRequest,
        id?: string,
        localAddressAddressAddress?: string,
        localAddressAddressAnyLocalAddress?: boolean,
        localAddressAddressCanonicalHostName?: string,
        localAddressAddressHostAddress?: string,
        localAddressAddressHostName?: string,
        localAddressAddressLinkLocalAddress?: boolean,
        localAddressAddressLoopbackAddress?: boolean,
        localAddressAddressMcGlobal?: boolean,
        localAddressAddressMcLinkLocal?: boolean,
        localAddressAddressMcNodeLocal?: boolean,
        localAddressAddressMcOrgLocal?: boolean,
        localAddressAddressMcSiteLocal?: boolean,
        localAddressAddressMulticastAddress?: boolean,
        localAddressAddressSiteLocalAddress?: boolean,
        localAddressHostName?: string,
        localAddressHostString?: string,
        localAddressPort?: number,
        localAddressUnresolved?: boolean,
        remoteAddressAddressAddress?: string,
        remoteAddressAddressAnyLocalAddress?: boolean,
        remoteAddressAddressCanonicalHostName?: string,
        remoteAddressAddressHostAddress?: string,
        remoteAddressAddressHostName?: string,
        remoteAddressAddressLinkLocalAddress?: boolean,
        remoteAddressAddressLoopbackAddress?: boolean,
        remoteAddressAddressMcGlobal?: boolean,
        remoteAddressAddressMcLinkLocal?: boolean,
        remoteAddressAddressMcNodeLocal?: boolean,
        remoteAddressAddressMcOrgLocal?: boolean,
        remoteAddressAddressMcSiteLocal?: boolean,
        remoteAddressAddressMulticastAddress?: boolean,
        remoteAddressAddressSiteLocalAddress?: boolean,
        remoteAddressHostName?: string,
        remoteAddressHostString?: string,
        remoteAddressPort?: number,
        remoteAddressUnresolved?: boolean,
        sslInfoPeerCertificates0BasicConstraints?: number,
        sslInfoPeerCertificates0Encoded?: string,
        sslInfoPeerCertificates0ExtendedKeyUsage?: Array<string>,
        sslInfoPeerCertificates0IssuerDnName?: string,
        sslInfoPeerCertificates0IssuerUniqueId?: Array<boolean>,
        sslInfoPeerCertificates0IssuerX500PrincipalEncoded?: string,
        sslInfoPeerCertificates0IssuerX500PrincipalName?: string,
        sslInfoPeerCertificates0KeyUsage?: Array<boolean>,
        sslInfoPeerCertificates0NotAfter?: string,
        sslInfoPeerCertificates0NotBefore?: string,
        sslInfoPeerCertificates0SerialNumber?: number,
        sslInfoPeerCertificates0SigAlgName?: string,
        sslInfoPeerCertificates0SigAlgOid?: string,
        sslInfoPeerCertificates0SigAlgParams?: string,
        sslInfoPeerCertificates0Signature?: string,
        sslInfoPeerCertificates0SubjectDnName?: string,
        sslInfoPeerCertificates0SubjectUniqueId?: Array<boolean>,
        sslInfoPeerCertificates0SubjectX500PrincipalEncoded?: string,
        sslInfoPeerCertificates0SubjectX500PrincipalName?: string,
        sslInfoPeerCertificates0TbsCertificate?: string,
        sslInfoPeerCertificates0Type?: string,
        sslInfoPeerCertificates0Version?: number,
        sslInfoSessionId?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/frontend-logger-service/v1/log',
            query: {
                'id': id,
                'localAddress.address.address': localAddressAddressAddress,
                'localAddress.address.anyLocalAddress': localAddressAddressAnyLocalAddress,
                'localAddress.address.canonicalHostName': localAddressAddressCanonicalHostName,
                'localAddress.address.hostAddress': localAddressAddressHostAddress,
                'localAddress.address.hostName': localAddressAddressHostName,
                'localAddress.address.linkLocalAddress': localAddressAddressLinkLocalAddress,
                'localAddress.address.loopbackAddress': localAddressAddressLoopbackAddress,
                'localAddress.address.MCGlobal': localAddressAddressMcGlobal,
                'localAddress.address.MCLinkLocal': localAddressAddressMcLinkLocal,
                'localAddress.address.MCNodeLocal': localAddressAddressMcNodeLocal,
                'localAddress.address.MCOrgLocal': localAddressAddressMcOrgLocal,
                'localAddress.address.MCSiteLocal': localAddressAddressMcSiteLocal,
                'localAddress.address.multicastAddress': localAddressAddressMulticastAddress,
                'localAddress.address.siteLocalAddress': localAddressAddressSiteLocalAddress,
                'localAddress.hostName': localAddressHostName,
                'localAddress.hostString': localAddressHostString,
                'localAddress.port': localAddressPort,
                'localAddress.unresolved': localAddressUnresolved,
                'remoteAddress.address.address': remoteAddressAddressAddress,
                'remoteAddress.address.anyLocalAddress': remoteAddressAddressAnyLocalAddress,
                'remoteAddress.address.canonicalHostName': remoteAddressAddressCanonicalHostName,
                'remoteAddress.address.hostAddress': remoteAddressAddressHostAddress,
                'remoteAddress.address.hostName': remoteAddressAddressHostName,
                'remoteAddress.address.linkLocalAddress': remoteAddressAddressLinkLocalAddress,
                'remoteAddress.address.loopbackAddress': remoteAddressAddressLoopbackAddress,
                'remoteAddress.address.MCGlobal': remoteAddressAddressMcGlobal,
                'remoteAddress.address.MCLinkLocal': remoteAddressAddressMcLinkLocal,
                'remoteAddress.address.MCNodeLocal': remoteAddressAddressMcNodeLocal,
                'remoteAddress.address.MCOrgLocal': remoteAddressAddressMcOrgLocal,
                'remoteAddress.address.MCSiteLocal': remoteAddressAddressMcSiteLocal,
                'remoteAddress.address.multicastAddress': remoteAddressAddressMulticastAddress,
                'remoteAddress.address.siteLocalAddress': remoteAddressAddressSiteLocalAddress,
                'remoteAddress.hostName': remoteAddressHostName,
                'remoteAddress.hostString': remoteAddressHostString,
                'remoteAddress.port': remoteAddressPort,
                'remoteAddress.unresolved': remoteAddressUnresolved,
                'sslInfo.peerCertificates[0].basicConstraints': sslInfoPeerCertificates0BasicConstraints,
                'sslInfo.peerCertificates[0].encoded': sslInfoPeerCertificates0Encoded,
                'sslInfo.peerCertificates[0].extendedKeyUsage': sslInfoPeerCertificates0ExtendedKeyUsage,
                'sslInfo.peerCertificates[0].issuerDN.name': sslInfoPeerCertificates0IssuerDnName,
                'sslInfo.peerCertificates[0].issuerUniqueID': sslInfoPeerCertificates0IssuerUniqueId,
                'sslInfo.peerCertificates[0].issuerX500Principal.encoded': sslInfoPeerCertificates0IssuerX500PrincipalEncoded,
                'sslInfo.peerCertificates[0].issuerX500Principal.name': sslInfoPeerCertificates0IssuerX500PrincipalName,
                'sslInfo.peerCertificates[0].keyUsage': sslInfoPeerCertificates0KeyUsage,
                'sslInfo.peerCertificates[0].notAfter': sslInfoPeerCertificates0NotAfter,
                'sslInfo.peerCertificates[0].notBefore': sslInfoPeerCertificates0NotBefore,
                'sslInfo.peerCertificates[0].serialNumber': sslInfoPeerCertificates0SerialNumber,
                'sslInfo.peerCertificates[0].sigAlgName': sslInfoPeerCertificates0SigAlgName,
                'sslInfo.peerCertificates[0].sigAlgOID': sslInfoPeerCertificates0SigAlgOid,
                'sslInfo.peerCertificates[0].sigAlgParams': sslInfoPeerCertificates0SigAlgParams,
                'sslInfo.peerCertificates[0].signature': sslInfoPeerCertificates0Signature,
                'sslInfo.peerCertificates[0].subjectDN.name': sslInfoPeerCertificates0SubjectDnName,
                'sslInfo.peerCertificates[0].subjectUniqueID': sslInfoPeerCertificates0SubjectUniqueId,
                'sslInfo.peerCertificates[0].subjectX500Principal.encoded': sslInfoPeerCertificates0SubjectX500PrincipalEncoded,
                'sslInfo.peerCertificates[0].subjectX500Principal.name': sslInfoPeerCertificates0SubjectX500PrincipalName,
                'sslInfo.peerCertificates[0].TBSCertificate': sslInfoPeerCertificates0TbsCertificate,
                'sslInfo.peerCertificates[0].type': sslInfoPeerCertificates0Type,
                'sslInfo.peerCertificates[0].version': sslInfoPeerCertificates0Version,
                'sslInfo.sessionId': sslInfoSessionId,
            },
            body: log,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
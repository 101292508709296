/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SlaReportCasesResponse } from '../models/SlaReportCasesResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SlaReportCasesService {

    /**
     * Get SLA report cases
     * @returns SlaReportCasesResponse OK
     * @throws ApiError
     */
    public static getSlaReportCases({
        xAuthScopeId,
        tscId,
        createdFrom,
        createdTo,
        sortBy,
        sortDirection,
        size,
        start,
        searchCaseId,
        searchCustomerReference,
        searchOrgNr,
        searchOrgName,
        searchSubscriptionProductName,
        searchSubscriptionId,
        searchServiceObjName,
        searchSubscriptionAlias,
        searchSubscriptionStreet,
        searchSubscriptionCity,
        searchSubscriptionCountry,
        searchShortDescription,
        searchDescription,
        searchCloseNotes,
        searchCloseCode,
        searchCreatedOn,
        searchResolvedOn,
        searchDuration,
        searchBusinessElapsedTime,
        searchClosedOn,
        searchGuaranteedTime,
        searchReportingPersonName,
        searchImpact,
        searchUrgency,
        searchPriority,
        searchCalculateSla,
        searchHasBreachedSla,
    }: {
        xAuthScopeId: any,
        tscId?: string,
        /** Date formats: YYYY-MM-DD **/
        createdFrom?: string,
        /** Date formats: YYYY-MM-DD **/
        createdTo?: string,
        sortBy?: 'CASE_ID' | 'SHORT_DESCRIPTION' | 'STATUS' | 'CATEGORY' | 'UPDATED_ON' | 'SUBSCRIPTION_ALIAS' | 'CREATED_ON' | 'SUBSCRIPTION_ID' | 'REPORTING_PERSON' | 'CUSTOMER_REFERENCE' | 'COUNTRY' | 'CITY' | 'STREET' | 'ORGANIZATION_NUMBER' | 'ORGANIZATION_NAME' | 'SUBSCRIPTION_PRODUCT_NAME' | 'SERVICE_OBJECT_NAME' | 'DESCRIPTION' | 'CLOSE_NOTES' | 'CLOSE_CODE' | 'RESOLVED_ON' | 'DURATION' | 'BUSINESS_ELAPSED_TIME' | 'CLOSED_ON' | 'IMPACT' | 'URGENCY' | 'PRIORITY' | 'GUARANTEED_TIME' | 'CALCULATE_SLA' | 'HAS_BREACHED_SLA',
        sortDirection?: 'ASC' | 'DESC',
        size?: number,
        start?: number,
        searchCaseId?: string,
        searchCustomerReference?: string,
        searchOrgNr?: string,
        searchOrgName?: string,
        searchSubscriptionProductName?: string,
        searchSubscriptionId?: string,
        searchServiceObjName?: string,
        searchSubscriptionAlias?: string,
        searchSubscriptionStreet?: string,
        searchSubscriptionCity?: string,
        searchSubscriptionCountry?: string,
        searchShortDescription?: string,
        searchDescription?: string,
        searchCloseNotes?: string,
        searchCloseCode?: string,
        searchCreatedOn?: string,
        searchResolvedOn?: string,
        searchDuration?: string,
        searchBusinessElapsedTime?: string,
        searchClosedOn?: string,
        searchGuaranteedTime?: string,
        searchReportingPersonName?: string,
        searchImpact?: number,
        searchUrgency?: number,
        searchPriority?: number,
        searchCalculateSla?: boolean,
        searchHasBreachedSla?: boolean,
    }): CancelablePromise<SlaReportCasesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/sla-report-cases',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'tscId': tscId,
                'createdFrom': createdFrom,
                'createdTo': createdTo,
                'sortBy': sortBy,
                'sortDirection': sortDirection,
                'size': size,
                'start': start,
                'search.caseId': searchCaseId,
                'search.customerReference': searchCustomerReference,
                'search.orgNr': searchOrgNr,
                'search.orgName': searchOrgName,
                'search.subscriptionProductName': searchSubscriptionProductName,
                'search.subscriptionId': searchSubscriptionId,
                'search.serviceObjName': searchServiceObjName,
                'search.subscriptionAlias': searchSubscriptionAlias,
                'search.subscriptionStreet': searchSubscriptionStreet,
                'search.subscriptionCity': searchSubscriptionCity,
                'search.subscriptionCountry': searchSubscriptionCountry,
                'search.shortDescription': searchShortDescription,
                'search.description': searchDescription,
                'search.closeNotes': searchCloseNotes,
                'search.closeCode': searchCloseCode,
                'search.createdOn': searchCreatedOn,
                'search.resolvedOn': searchResolvedOn,
                'search.duration': searchDuration,
                'search.businessElapsedTime': searchBusinessElapsedTime,
                'search.closedOn': searchClosedOn,
                'search.guaranteedTime': searchGuaranteedTime,
                'search.reportingPersonName': searchReportingPersonName,
                'search.impact': searchImpact,
                'search.urgency': searchUrgency,
                'search.priority': searchPriority,
                'search.calculateSla': searchCalculateSla,
                'search.hasBreachedSla': searchHasBreachedSla,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                405: `Method Not Allowed`,
                415: `Unsupported Media Type`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Export SLA report cases
     * @returns string OK
     * @throws ApiError
     */
    public static exportSlaReportCases({
        xAuthScopeId,
        acceptLanguage,
        tscId,
        createdFrom,
        createdTo,
        sortBy,
        sortDirection,
        size,
        start,
        searchCaseId,
        searchCustomerReference,
        searchOrgNr,
        searchOrgName,
        searchSubscriptionProductName,
        searchSubscriptionId,
        searchServiceObjName,
        searchSubscriptionAlias,
        searchSubscriptionStreet,
        searchSubscriptionCity,
        searchSubscriptionCountry,
        searchShortDescription,
        searchDescription,
        searchCloseNotes,
        searchCloseCode,
        searchCreatedOn,
        searchResolvedOn,
        searchDuration,
        searchBusinessElapsedTime,
        searchClosedOn,
        searchGuaranteedTime,
        searchReportingPersonName,
        searchImpact,
        searchUrgency,
        searchPriority,
        searchCalculateSla,
        searchHasBreachedSla,
    }: {
        xAuthScopeId: any,
        acceptLanguage: any,
        tscId?: string,
        /** Date formats: YYYY-MM-DD **/
        createdFrom?: string,
        /** Date formats: YYYY-MM-DD **/
        createdTo?: string,
        sortBy?: 'CASE_ID' | 'SHORT_DESCRIPTION' | 'STATUS' | 'CATEGORY' | 'UPDATED_ON' | 'SUBSCRIPTION_ALIAS' | 'CREATED_ON' | 'SUBSCRIPTION_ID' | 'REPORTING_PERSON' | 'CUSTOMER_REFERENCE' | 'COUNTRY' | 'CITY' | 'STREET' | 'ORGANIZATION_NUMBER' | 'ORGANIZATION_NAME' | 'SUBSCRIPTION_PRODUCT_NAME' | 'SERVICE_OBJECT_NAME' | 'DESCRIPTION' | 'CLOSE_NOTES' | 'CLOSE_CODE' | 'RESOLVED_ON' | 'DURATION' | 'BUSINESS_ELAPSED_TIME' | 'CLOSED_ON' | 'IMPACT' | 'URGENCY' | 'PRIORITY' | 'GUARANTEED_TIME' | 'CALCULATE_SLA' | 'HAS_BREACHED_SLA',
        sortDirection?: 'ASC' | 'DESC',
        size?: number,
        start?: number,
        searchCaseId?: string,
        searchCustomerReference?: string,
        searchOrgNr?: string,
        searchOrgName?: string,
        searchSubscriptionProductName?: string,
        searchSubscriptionId?: string,
        searchServiceObjName?: string,
        searchSubscriptionAlias?: string,
        searchSubscriptionStreet?: string,
        searchSubscriptionCity?: string,
        searchSubscriptionCountry?: string,
        searchShortDescription?: string,
        searchDescription?: string,
        searchCloseNotes?: string,
        searchCloseCode?: string,
        searchCreatedOn?: string,
        searchResolvedOn?: string,
        searchDuration?: string,
        searchBusinessElapsedTime?: string,
        searchClosedOn?: string,
        searchGuaranteedTime?: string,
        searchReportingPersonName?: string,
        searchImpact?: number,
        searchUrgency?: number,
        searchPriority?: number,
        searchCalculateSla?: boolean,
        searchHasBreachedSla?: boolean,
    }): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/sla-report-cases/export',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
                'Accept-Language': acceptLanguage,
            },
            query: {
                'tscId': tscId,
                'createdFrom': createdFrom,
                'createdTo': createdTo,
                'sortBy': sortBy,
                'sortDirection': sortDirection,
                'size': size,
                'start': start,
                'search.caseId': searchCaseId,
                'search.customerReference': searchCustomerReference,
                'search.orgNr': searchOrgNr,
                'search.orgName': searchOrgName,
                'search.subscriptionProductName': searchSubscriptionProductName,
                'search.subscriptionId': searchSubscriptionId,
                'search.serviceObjName': searchServiceObjName,
                'search.subscriptionAlias': searchSubscriptionAlias,
                'search.subscriptionStreet': searchSubscriptionStreet,
                'search.subscriptionCity': searchSubscriptionCity,
                'search.subscriptionCountry': searchSubscriptionCountry,
                'search.shortDescription': searchShortDescription,
                'search.description': searchDescription,
                'search.closeNotes': searchCloseNotes,
                'search.closeCode': searchCloseCode,
                'search.createdOn': searchCreatedOn,
                'search.resolvedOn': searchResolvedOn,
                'search.duration': searchDuration,
                'search.businessElapsedTime': searchBusinessElapsedTime,
                'search.closedOn': searchClosedOn,
                'search.guaranteedTime': searchGuaranteedTime,
                'search.reportingPersonName': searchReportingPersonName,
                'search.impact': searchImpact,
                'search.urgency': searchUrgency,
                'search.priority': searchPriority,
                'search.calculateSla': searchCalculateSla,
                'search.hasBreachedSla': searchHasBreachedSla,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                405: `Method Not Allowed`,
                415: `Unsupported Media Type`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

}
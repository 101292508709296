/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RegistrationRequest } from '../models/RegistrationRequest';
import type { RegistrationResponse } from '../models/RegistrationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Register a prepaid sim card
     * @param body
     * @returns RegistrationResponse Success
     * @throws ApiError
     */
    public static register(
        body: RegistrationRequest,
    ): CancelablePromise<RegistrationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/registration',
            body: body,
            errors: {
                400: `Bad request`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FindOfferingsRequest } from '../models/FindOfferingsRequest';
import type { FindOfferingsSuccessResponse } from '../models/FindOfferingsSuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Fetch available offerings for an asset
     * @param body
     * @returns FindOfferingsSuccessResponse Operation went as expected.
     * @throws ApiError
     */
    public static findOfferingsV1(
        body: FindOfferingsRequest,
    ): CancelablePromise<FindOfferingsSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1',
            body: body,
            errors: {
                400: `Request is missing a required field, or has faulty formatting.`,
                500: `Unexpected error.`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreditCardInput } from '../models/CreditCardInput';
import type { ProcessPaymentRequest } from '../models/ProcessPaymentRequest';
import type { ResponseWrapperPresentationOrder } from '../models/ResponseWrapperPresentationOrder';
import type { ResponseWrapperProcessPaymentResponse } from '../models/ResponseWrapperProcessPaymentResponse';
import type { ResponseWrapperStatusResponse } from '../models/ResponseWrapperStatusResponse';
import type { UpdatePaymentRequest } from '../models/UpdatePaymentRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PublicV3Service {

    /**
     * Fetch a payment order
     * @param orderId
     * @returns ResponseWrapperPresentationOrder OK
     * @throws ApiError
     */
    public static getPaymentPublic(
        orderId: number,
    ): CancelablePromise<ResponseWrapperPresentationOrder> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/order/{orderId}',
            path: {
                'orderId': orderId,
            },
            errors: {
                400: `Order does not exist.`,
                403: `You do not have access to this payment.`,
                410: `Gone`,
            },
        });
    }

    /**
     * Process a payment order
     * @param orderId
     * @param requestBody
     * @returns ResponseWrapperProcessPaymentResponse An attempt to 'process' the payment was made. The outcome might not have been success (check the 'success' field in the body). In case of a successful outcome, the response body will contain data needed to complete the payment (if any). For some payment methods, the payment might complete immediately.
     * @throws ApiError
     */
    public static process(
        orderId: number,
        requestBody: ProcessPaymentRequest,
    ): CancelablePromise<ResponseWrapperProcessPaymentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/order/{orderId}',
            path: {
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Either the request was malformed or or unsuitable for the payment method, the payment is not in an appropriate state, or the payment does not exist.`,
                403: `You do not have access to this payment.`,
                410: `Gone`,
            },
        });
    }

    /**
     * Check the status of a payment. Unlike GETing /orders/{orderId}, this endpoint will not return a cached status and is thus suitable for polling.
     * @param orderId
     * @returns any Polled successfully.
     * @throws ApiError
     */
    public static poll(
        orderId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/order/{orderId}/poll',
            path: {
                'orderId': orderId,
            },
            errors: {
                400: `Order does not exist.`,
                403: `You do not have access to this payment.`,
                410: `Gone`,
            },
        });
    }

    /**
     * Finish a payment that required redirection after /process was called. Used for 3D-secure.
     * @param orderId
     * @param requestBody
     * @returns ResponseWrapperStatusResponse OK
     * @throws ApiError
     */
    public static finish(
        orderId: number,
        requestBody: CreditCardInput,
    ): CancelablePromise<ResponseWrapperStatusResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/order/{orderId}/finish',
            path: {
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Change the payment method of an existing order. If there is an in-progress payment it will be aborted, as if by /abort.
     * @param orderId
     * @param requestBody
     * @returns ResponseWrapperPresentationOrder OK
     * @throws ApiError
     */
    public static changePaymentMethod(
        orderId: number,
        requestBody: UpdatePaymentRequest,
    ): CancelablePromise<ResponseWrapperPresentationOrder> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/order/{orderId}/change-method',
            path: {
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Abort and ongoing payment attempt. This does not cancel the order and new payment attempts can be initiated by calling /process.
     * @param orderId
     * @returns ResponseWrapperPresentationOrder OK
     * @throws ApiError
     */
    public static abort(
        orderId: number,
    ): CancelablePromise<ResponseWrapperPresentationOrder> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/order/{orderId}/abort',
            path: {
                'orderId': orderId,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EligibilityRequest } from '../models/EligibilityRequest';
import type { EligibilityResponseWrapper } from '../models/EligibilityResponseWrapper';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class V2CiamAuthService {

    /**
     * @param requestBody
     * @returns EligibilityResponseWrapper OK
     * @throws ApiError
     */
    public static eligibility(
        requestBody?: EligibilityRequest,
    ): CancelablePromise<EligibilityResponseWrapper> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/eligibility',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
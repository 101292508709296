/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgreementListDTO } from '../models/AgreementListDTO';
import type { AgreementsExistsResponseDTO } from '../models/AgreementsExistsResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MyBusinessAgreementControllerService {

    /**
     * Get all agreements that an AccessProfile has access to.
     * #token.isAuthenticated()
     * @param tscids tscids
     * @param groupId Group ID
     * @returns AgreementListDTO OK
     * @throws ApiError
     */
    public static getAgreementsByTscidsOnAccessProfileUsingGet(
        tscids: Array<string>,
        groupId?: number,
    ): CancelablePromise<AgreementListDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/agreements',
            path: {
                'groupId': groupId,
            },
            query: {
                'tscids': tscids,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Returns true if there are any agreements connected to the group.
     * #token.hasAccess('MANAGE_USER')
     * @param groupId Group ID
     * @returns AgreementsExistsResponseDTO OK
     * @throws ApiError
     */
    public static getAgreementsExistsOnGroupUsingGet(
        groupId?: number,
    ): CancelablePromise<AgreementsExistsResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/agreements/exists',
            path: {
                'groupId': groupId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
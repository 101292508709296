/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResponseWrapperValidationResponse } from '../models/ResponseWrapperValidationResponse';
import type { ValidationRequest } from '../models/ValidationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PrepaidControllerService {

    /**
     * Check if a MSISDN is eligible for prepaid topups.
     * @param requestBody
     * @returns ResponseWrapperValidationResponse Validation was performed. Check data.valid for outcome.
     * @throws ApiError
     */
    public static validate(
        requestBody: ValidationRequest,
    ): CancelablePromise<ResponseWrapperValidationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/validate-topup',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Request is missing a required field, or has faulty formatting.`,
                500: `Unexpected error.`,
            },
        });
    }

}
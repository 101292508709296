/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChargeByVoucherRequest } from '../models/ChargeByVoucherRequest';
import type { ChargeByVoucherResponse } from '../models/ChargeByVoucherResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChargeByVoucherService {

    /**
     * Charge prepaid SIM card with a voucher.
     * @param requestBody
     * @returns ChargeByVoucherResponse Operation went as expected.
     * @throws ApiError
     */
    public static create(
        requestBody: ChargeByVoucherRequest,
    ): CancelablePromise<ChargeByVoucherResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/prepaid-by-voucher',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Request is missing a required field, or has faulty formatting.`,
                500: `Unexpected error.`,
            },
        });
    }

}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExistingUserOrInviteDTO } from '../models/ExistingUserOrInviteDTO';
import type { GetIsUserFederatedResponseDTO } from '../models/GetIsUserFederatedResponseDTO';
import type { ProfileDTO } from '../models/ProfileDTO';
import type { ResponseEntity } from '../models/ResponseEntity';
import type { UpdateAccessProfileRequestDTO } from '../models/UpdateAccessProfileRequestDTO';
import type { UserInfoDTO } from '../models/UserInfoDTO';
import type { UserProfileListDTO } from '../models/UserProfileListDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MyBusinessUserControllerService {

    /**
     * Get user info for all users with a profile connected to a customer group
     * #token.hasAccess('MANAGE_USER')
     * @param groupId Customer Group ID
     * @returns UserProfileListDTO OK
     * @throws ApiError
     */
    public static getUsersInCustomerGroupUsingGet(
        groupId?: number,
    ): CancelablePromise<UserProfileListDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/users',
            path: {
                'groupId': groupId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Search for user information based on email address or mobile phone number
     * #token.hasAccess('MANAGE_USER')
     * @param email The email address to search for
     * @param groupId Customer Group ID
     * @param phone The phone number to search for
     * @returns ExistingUserOrInviteDTO OK
     * @throws ApiError
     */
    public static searchUserByEmailOrPhoneUsingGet(
        email?: string,
        groupId?: number,
        phone?: string,
    ): CancelablePromise<ExistingUserOrInviteDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/users/search',
            path: {
                'groupId': groupId,
            },
            query: {
                'email': email,
                'phone': phone,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Updates the users accessProfile
     * #token.hasAccess('MANAGE_USER')
     * @param id Id of the AccessProfile to update
     * @param tcwssId Id of the user owning the access profile
     * @param updateAccessProfileRequestDto updateAccessProfileRequestDTO
     * @param groupId Customer Group ID
     * @returns UpdateAccessProfileRequestDTO OK
     * @returns any Created
     * @throws ApiError
     */
    public static updateProfileUsingPut(
        id: number,
        tcwssId: string,
        updateAccessProfileRequestDto: UpdateAccessProfileRequestDTO,
        groupId?: number,
    ): CancelablePromise<UpdateAccessProfileRequestDTO | any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/users/{tcwssId}/accessprofiles/{id}',
            path: {
                'groupId': groupId,
                'id': id,
                'tcwssId': tcwssId,
            },
            body: updateAccessProfileRequestDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Returns information about a user fetched from EAM
     * #token.hasAccess('MANAGE_USER')
     * @param groupId Customer Group ID
     * @param tcwssid TCWSSID for the user
     * @returns UserInfoDTO OK
     * @throws ApiError
     */
    public static getUserInfoUsingGet(
        groupId?: number,
        tcwssid?: string,
    ): CancelablePromise<UserInfoDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/users/{tcwssid}',
            path: {
                'groupId': groupId,
                'tcwssid': tcwssid,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Return if user is federated
     * #token.hasAccess('MYBUSINESS_ACCESS')
     * @param tcwssid User ID
     * @param groupId Customer Group ID
     * @returns GetIsUserFederatedResponseDTO OK
     * @throws ApiError
     */
    public static getIsUserFederatedUsingGet(
        tcwssid: string,
        groupId?: number,
    ): CancelablePromise<GetIsUserFederatedResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/users/{tcwssid}/isfederated',
            path: {
                'groupId': groupId,
                'tcwssid': tcwssid,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get the accessprofile associated with the profile specified by TCWSSID and groupId
     * #token.hasAccess('MANAGE_USER')
     * @param tcwssid User ID
     * @param groupId Customer Group ID
     * @returns ProfileDTO Successful response
     * @throws ApiError
     */
    public static getUserProfileUsingGet(
        tcwssid: string,
        groupId?: number,
    ): CancelablePromise<ProfileDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/users/{tcwssid}/profile',
            path: {
                'groupId': groupId,
                'tcwssid': tcwssid,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Remove a user profile and its associated access profiles
     * #token.hasAccess('MANAGE_USER')
     * @param groupId Customer Group ID
     * @param tcwssid TCWSSID
     * @returns ResponseEntity OK
     * @throws ApiError
     */
    public static removeUserProfileUsingDelete(
        groupId?: number,
        tcwssid?: string,
    ): CancelablePromise<ResponseEntity> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/corp-sra-service/public/mybusiness/customergroups/{groupId}/users/{tcwssid}/profile',
            path: {
                'groupId': groupId,
                'tcwssid': tcwssid,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

}